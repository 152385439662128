import { forwardRef, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Button,
  FieldChangeEvent,
  NakedUl,
  Text,
  useAlert,
  useForm,
  useModalControl,
  useTooltip,
} from '@frontend/design-system';
import { Accept, Thumb } from '..';
import { analyzingCallLottie } from '../../assets';
import { DEFAULT_FIELD_EMPTY_VALUE } from '../../constants';
import { featureFlags } from '../../feature-flags';
import { generateTrackingId } from '../../tracking';
import { callIntelligenceUtils } from '../../utils';
import { AnalyzingCallLoader } from './analyzing-call-loader';
import ChecklistBadgeDropdownMenu, { BadgeOption } from './badge-dropdown-menu/checklist-badge-dropdown-menu';
import NestedBadgeDropdownMenu from './badge-dropdown-menu/nested-badge-dropdown-menu';
import RadioBadgeDropdownMenu from './badge-dropdown-menu/radio-badge-dropdown-menu';
import { CallAnalyzeStatus } from './call-analyze-status';
import CallIntelChip from './call-intel-chip';
import CorrectionBanner from './correction-banner';
import { EditableField } from './editable-field';
import { useCallIntelShallowStore, useCallTakeawayPanelShallowStore } from './hooks';
import { RecordOptions, useOptionsProvider } from './hooks/use-options-provider';
import EditHistoryModal, { FIELD_MAPPING } from './modal/edit-history-modal';
import SuggestionModal from './modal/suggestion-modal';

/**
 * Computes the difference between two objects and returns changed values.
 */
export const diffValues = <T extends Record<string, any>>(initial: T, current: T): Partial<T> | null => {
  const diff = Object.keys(initial).reduce((acc, key) => {
    const initialValue = initial[key];
    const currentValue = current[key];

    if (currentValue === undefined) return acc;

    const areEqual = Array.isArray(initialValue)
      ? initialValue.length === currentValue.length && initialValue.every((value: any) => currentValue.includes(value))
      : initialValue === currentValue;

    if (!areEqual) {
      (acc as Record<string, any>)[key] = currentValue;
    }

    return acc;
  }, {} as Partial<T>);

  return Object.keys(diff).length > 0 ? diff : null;
};

/**
 * Transforms RecordOptions into a flat Record<string, BadgeOption>.
 */
export const transformOptionsToBadgeOptions = (
  options: RecordOptions,
  customLabels?: Record<string, string>
): Record<string, BadgeOption> => {
  return Object.entries(options).reduce<Record<string, BadgeOption>>((acc, [key, value]) => {
    acc[key] = {
      chip: value,
      label: customLabels?.[key] ?? '',
    };
    return acc;
  }, {});
};

/**
 * Transforms RecordOptions with nested submenus into Record<string, BadgeOption>.
 */
const transformOptionsToNestedBadgeOptions = (
  optionsWithSubmenus: RecordOptions,
  suboptionsMap: Record<string, RecordOptions>,
  customLabels?: Record<string, string>
): Record<string, BadgeOption> => {
  return Object.entries(optionsWithSubmenus).reduce<Record<string, BadgeOption>>((acc, [key, value]) => {
    acc[key] = {
      chip: value,
      label: customLabels?.[key] ?? '',
      submenuOptions: suboptionsMap[key]
        ? Object.entries(suboptionsMap[key]).reduce<Record<string, BadgeOption>>((submenuAcc, [subKey, subValue]) => {
            submenuAcc[subKey] = {
              chip: subValue,
              label: customLabels?.[subKey] ?? '',
            };
            return submenuAcc;
          }, {})
        : undefined,
    };
    return acc;
  }, {});
};

/**
 * Replaces all empty string ("") values in an object with `undefined`.
 * Useful when working with forms where empty string fields should be treated as undefined.
 */

const normalizeEmptyStrings = <T extends Record<string, any>>(obj: T): T => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === '' ? undefined : value])) as T;
};

const mapFormStateToCall = (form: FormState): Partial<CallIntelTypes.Call> => {
  return {
    appointmentTypes: form.appointmentTypes.length > 0 ? form.appointmentTypes : undefined,
    categories: form.categories.length > 0 ? form.categories : undefined,
    contactType: form.contactType === undefined ? undefined : form.contactType,
    callSchedulingOpportunity:
      form.schedulingOpportunity === undefined
        ? null
        : form.schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED,
    callSchedulingOutcome:
      form.schedulingOutcome === undefined
        ? null
        : form.schedulingOutcome === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED,
    callSentiment: form.callSentiment === undefined ? null : { sentiment: form.callSentiment },
  };
};

export const computeIsFieldEdited = (
  currentEdited: CallIntelTypes.IsFieldEdited,
  changedFields: (keyof CallIntelTypes.CallEditableFields)[]
) => {
  const updatedEdited: CallIntelTypes.IsFieldEdited = { ...currentEdited };

  changedFields.forEach((field) => {
    const editedKey = fieldToEditedKeyMap[field];
    if (editedKey) {
      updatedEdited[editedKey] = true;
    }
  });

  return updatedEdited;
};

const fieldToEditedKeyMap: Record<keyof CallIntelTypes.CallEditableFields, keyof CallIntelTypes.IsFieldEdited> = {
  appointmentTypes: 'isAppointmentTypeEdited',
  categories: 'isCategoryEdited',
  contactType: 'isContactTypeEdited',
  schedulingOpportunity: 'isSchedulingOpportunityEdited',
  schedulingOutcome: 'isSchedulingOutcomeEdited',
  callSentiment: 'isSentimentEdited',
  serviceQualityFlags: 'isServiceQualityFlagEdited',
};

type Props = {
  call: CallIntelTypes.Call;
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
};

export type SuggestionModalConfig = {
  isOpen: boolean;
  type: string;
};

type FormSchedulingOutcome = CallIntelTypes.SchedulingOutcomeEnum | 'none';

type FormState = {
  appointmentTypes: CallIntelTypes.AppointmentTypeEnum[];
  categories: CallIntelTypes.CategoryEnum[];
  contactType: CallIntelTypes.ContactTypeEnum;
  schedulingOpportunity: CallIntelTypes.SchedulingOpportunityEnum;
  schedulingOutcome: FormSchedulingOutcome;
  callSentiment: CallIntelTypes.SentimentEnum;
};

export const CallAnalysis = forwardRef<HTMLDivElement, Props>(
  ({ call, isFailedCall, isPoorAudio, isSkippedCall }, ref) => {
    const { t } = useTranslation('analytics');
    const alert = useAlert();
    const { chipVariants, dataLabels } = useCallIntelShallowStore('chipVariants', 'dataLabels');
    const {
      allCalls,
      editedCallIds,
      isDemoAccount,
      setEditedCallIds,
      setAllCalls,
      selectedCall,
      setSelectedCall,
      setUnsavedEditChangesConfig,
      unsavedEditChangesConfig,
    } = useCallTakeawayPanelShallowStore(
      'allCalls',
      'editedCallIds',
      'isDemoAccount',
      'setEditedCallIds',
      'setAllCalls',
      'selectedCall',
      'setSelectedCall',
      'setUnsavedEditChangesConfig',
      'unsavedEditChangesConfig'
    );

    const { modalProps, triggerProps, closeModal } = useModalControl();
    const { getOptions } = useOptionsProvider();
    const { getFeatureFlagValue } = useScopedAppFlagStore();
    const isEditAiEnabled = getFeatureFlagValue(featureFlags.enableCallIntelEditAi);
    const isVariantB = getFeatureFlagValue(featureFlags.editAiDesignVariant); // true for design B , false for Deisgn A

    const isCallInProcessing = useMemo(() => callIntelligenceUtils.isCallInProcessing(call.status), [call.status]);
    const isFormEdited = useMemo(() => Object.values(call?.isFieldEdited ?? {}).some((value) => value), [call]);

    const [isEditHistoryOpen, setEditHistoryOpen] = useState(false);
    const [suggestionModalConfig, setSuggestionModalConfig] = useState<SuggestionModalConfig>({
      isOpen: false,
      type: '',
    });

    const isOnlyPoorAudio = isPoorAudio && !isSkippedCall && !isFailedCall;
    const isSkippedOrFailedCall = isSkippedCall || isFailedCall;

    const [correctionBannerConfig, setCorrectionBannerConfig] = useState({
      canMakeCorrection: isVariantB,
      isReviewed: isFormEdited,
      showEditHistory: isFormEdited,
    });
    const { canMakeCorrection, isReviewed, showEditHistory } = correctionBannerConfig;
    const [isEditMode, setEditMode] = useState(
      !isEditAiEnabled ? false : isSkippedOrFailedCall ? false : isVariantB ? false : true
    );
    const [changedFields, setChangedFields] = useState<(keyof typeof callFormState)[] | null>(null);
    const [isFormLevelSaveDisabled, setIsFormLevelSaveDisabled] = useState(true);

    const initialFormState = useMemo(
      () => ({
        appointmentTypes: call.appointmentTypes ?? [],
        categories: call.categories ?? [],
        contactType: call.contactType ?? '',
        schedulingOpportunity:
          call.callSchedulingOpportunity === null
            ? undefined
            : call.callSchedulingOpportunity
            ? CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED
            : CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED,
        schedulingOutcome:
          call.callSchedulingOutcome === null
            ? undefined
            : call.callSchedulingOpportunity
            ? call.callSchedulingOutcome
              ? CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED
              : CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED
            : 'none',
        callSentiment: call.callSentiment?.sentiment,
      }),
      [call]
    );

    const [callFormState, setCallFormState] = useState(initialFormState);

    const [pendingPayload, setPendingPayload] = useState<null | CallIntelTypes.EditAIOutputRequest>(null);
    const [pendingUpdateCallInList, setPendingUpdateCallInList] = useState<null | Partial<CallIntelTypes.Call>>(null);
    const [isRestored, setIsRestored] = useState(false);

    const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();

    const {
      changedValues: formChangedValues,
      getFieldProps,
      isComplete: isFormComplete,
      reset: resetForm,
      seedValues: seedValues,
      values: formValues,
    } = useForm({
      computeChangedValues: true,
      fields: {
        appointmentTypes: { type: 'checklist', value: initialFormState.appointmentTypes },
        categories: { type: 'checklist', value: initialFormState.categories, required: true },
        contactType: { type: 'radio', value: initialFormState.contactType },
        schedulingOpportunity: {
          type: 'radio',
          value: initialFormState.schedulingOpportunity,
        },
        schedulingOutcome: {
          type: 'radio',
          value: initialFormState.schedulingOutcome,
        },
        callSentiment: { type: 'radio', value: initialFormState.callSentiment },
      },
    });

    const { mutate: fakeSubmitApi, isLoading: isFakeLoading } = useMutation({
      mutationFn: () => CallIntelligenceApi.fakeApi(null, 200),
    });

    const { mutate: submitEditedOuputApi, isLoading } = useMutation({
      mutationFn: !isDemoAccount ? CallIntelligenceApi.editAIOutput : CallIntelligenceApi.noopMutationFn,
      onSuccess: () => {
        if (isVariantB) {
          setCallFormState(normalizeEmptyStrings(formValues) as typeof callFormState);

          setEditMode(false);
          setCorrectionBannerConfig((prev) => ({
            ...prev,
            canMakeCorrection: true,
            isReviewed: true,
            showEditHistory: true,
          }));

          const updatedCallFields = mapFormStateToCall(normalizeEmptyStrings(formValues) as FormState);

          setPendingUpdateCallInList(updatedCallFields);
        } else {
          const updatedCallFields = mapFormStateToCall(callFormState as FormState);
          setPendingUpdateCallInList(updatedCallFields);
          setPendingPayload(null);
          setEditMode(true);
          setCorrectionBannerConfig((prev) => ({
            ...prev,
            canMakeCorrection: false,
            isReviewed: true,
            showEditHistory: true,
          }));
          resetForm();
        }
        alert.success(t('Successfully saved changes!'));
      },
      onError: (err) => {
        console.error(err);
        if (http.isHttpError(err)) {
          const errorMessage =
            ((err.data as Record<string, any>)?.code as number) !== 3
              ? t('Unable to save changes. Please try again.')
              : t('Updates were made by another user. Please refresh and try again.');
          alert.error(errorMessage);
        }
      },
      onSettled: () => {},
    });

    const computeVariantBChangedPayload = (watch = false): CallIntelTypes.EditAIOutputRequest | false => {
      if (formChangedValues && Object.keys(formChangedValues).length > 0) {
        const user = getUser();
        if (!user) return false;

        // Transform current fields from formValues
        const currentFields = Object.keys(formChangedValues).reduce((acc, key) => {
          const typedKey = key as keyof typeof formChangedValues;
          if (typedKey === 'schedulingOpportunity') {
            if (callFormState[typedKey] === undefined) {
              acc[typedKey] = null;
            } else {
              acc[typedKey] =
                callFormState.schedulingOpportunity ===
                CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
            }
          } else if (typedKey === 'schedulingOutcome') {
            if (callFormState[typedKey] === undefined) {
              acc[typedKey] = null;
            } else {
              acc[typedKey] =
                callFormState.schedulingOutcome === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED;
            }
          } else if (typedKey === 'callSentiment' && callFormState[typedKey] === undefined) {
            acc[typedKey] = null;
          } else {
            acc[typedKey] = callFormState[typedKey];
          }
          return acc;
        }, {} as Partial<Record<keyof typeof formChangedValues, any>>);

        // Transform edited fields from parent values
        const editedFields = Object.keys(formChangedValues).reduce((acc, key) => {
          const typedKey = key as keyof typeof formChangedValues;
          if (typedKey === 'schedulingOpportunity') {
            acc[typedKey] =
              formValues.schedulingOpportunity ===
              CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
          } else if (typedKey === 'schedulingOutcome') {
            acc[typedKey] =
              formValues.schedulingOutcome === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED;
          } else {
            acc[typedKey] = formValues[typedKey];
          }
          return acc;
        }, {} as Partial<Record<keyof typeof formChangedValues, any>>);

        !watch && setChangedFields(Object.keys(formChangedValues) as (keyof typeof callFormState)[]);

        return {
          callId: call?.id as string,
          current_fields: currentFields,
          edited_fields: editedFields,
          location_id: call?.locationId as string,
          user_id: user?.userID as string,
        };
      }
      return false;
    };

    const computeVariantAChangedPayload = (
      changedValues: Partial<typeof callFormState> | null,
      newValues: Partial<typeof callFormState>
    ): CallIntelTypes.EditAIOutputRequest | false => {
      if (changedValues && Object.keys(changedValues).length > 0) {
        const user = getUser();
        if (!user) return false;

        const currentFields = Object.keys(changedValues).reduce((acc, key) => {
          const typedKey = key as keyof typeof changedValues;
          if (typedKey === 'schedulingOpportunity') {
            acc[typedKey] =
              callFormState.schedulingOpportunity ===
              CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
          } else if (typedKey === 'schedulingOutcome') {
            acc[typedKey] =
              callFormState.schedulingOutcome === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED;
          } else {
            acc[typedKey] = callFormState[typedKey];
          }
          return acc;
        }, {} as Partial<Record<keyof typeof changedValues, any>>);

        const editedFields = Object.keys(changedValues).reduce((acc, key) => {
          const typedKey = key as keyof typeof changedValues;
          if (typedKey === 'schedulingOpportunity') {
            acc[typedKey] =
              newValues.schedulingOpportunity ===
              CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
          } else if (typedKey === 'schedulingOutcome') {
            acc[typedKey] =
              newValues.schedulingOutcome === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED;
          } else {
            acc[typedKey] = newValues[typedKey];
          }
          return acc;
        }, {} as Partial<Record<keyof typeof changedValues, any>>);

        setChangedFields(Object.keys(changedValues ?? {}) as (keyof typeof callFormState)[]);

        return {
          callId: call.id,
          current_fields: currentFields,
          edited_fields: editedFields,
          location_id: call.locationId,
          user_id: user.userID,
        };
      }

      return false;
    };

    const applyAndCommitVariantAChanges = (newValues: Partial<typeof callFormState>) => {
      const changedValues = diffValues(callFormState, newValues);
      const payload = computeVariantAChangedPayload(changedValues, newValues);
      if (payload === false) {
        fakeSubmitApi();
      }
      setCallFormState((prev) => ({ ...prev, ...newValues }));
      if (payload) setPendingPayload(payload);
    };

    const cancelEditMode = () => {
      if (isVariantB) {
        seedValues(callFormState);
        setEditMode(false);
        setCorrectionBannerConfig((prev) => ({ ...prev, canMakeCorrection: true }));
      }
    };

    const closeEditHistoryModal = () => {
      closeModal();
      setEditHistoryOpen(false);
    };

    const closeSuggestionModal = () => {
      closeModal();
      setSuggestionModalConfig({ isOpen: false, type: '' });
    };

    const handleDiscardChanges = (reset = true) => {
      if (isVariantB) {
        reset && resetForm();
        setUnsavedEditChangesConfig({ hasUnsavedChanges: !reset, actionType: 'NO_ACTION' });
      }
    };

    const initiateCorrectionMode = () => {
      setEditMode(isVariantB ? true : false);
      setCorrectionBannerConfig((prev) => ({ ...prev, canMakeCorrection: false }));
      resetForm();
    };

    const openEditHistoryModal = () => {
      setEditHistoryOpen(true);
      triggerProps.onClick();
    };

    const openSuggestionModal = (name: string) => {
      setSuggestionModalConfig({ isOpen: true, type: name });
      triggerProps.onClick();
    };

    const handleFieldSave = (e?: FieldChangeEvent) => {
      if (isVariantB) {
        const payload = computeVariantBChangedPayload();
        if (payload) {
          submitEditedOuputApi(payload);
        } else {
          setEditMode(false);
          setCorrectionBannerConfig((prev) => ({
            ...prev,
            canMakeCorrection: true,
          }));
        }
        setUnsavedEditChangesConfig({ hasUnsavedChanges: false, actionType: 'NO_ACTION' });
      } else {
        if (!e) return;
        //@ts-ignore - until FieldChangeEvent is fixed
        const fieldName = e.name as keyof typeof formValues;
        //@ts-ignore - until FieldChangeEvent is fixed
        const newValue = { [fieldName]: e?.target?.value ?? e.value };
        applyAndCommitVariantAChanges(newValue);
      }
    };

    const handleSchedulingOpportunitySelection = (primaryValue: string, secondaryValue?: string) => {
      const schedulingOpportunity = primaryValue as CallIntelTypes.SchedulingOpportunityEnum;
      const schedulingOutcome = secondaryValue as CallIntelTypes.SchedulingOutcomeEnum;
      if (isVariantB) {
        if (
          schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED &&
          !secondaryValue
        ) {
          getFieldProps('schedulingOpportunity').onChange({
            name: 'schedulingOpportunity',
            value: primaryValue,
          });
        } else if (
          schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED
        ) {
          getFieldProps('schedulingOpportunity').onChange({ name: 'schedulingOpportunity', value: primaryValue });
          getFieldProps('schedulingOutcome').onChange({
            name: 'schedulingOutcome',
            value: 'none',
          });
        } else {
          getFieldProps('schedulingOpportunity').onChange({ name: 'schedulingOpportunity', value: primaryValue });
          getFieldProps('schedulingOutcome').onChange({
            name: 'schedulingOutcome',
            value: secondaryValue,
          });
        }
      } else {
        if (schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED) {
          applyAndCommitVariantAChanges({
            schedulingOpportunity,
            schedulingOutcome: 'none',
          });
        } else {
          applyAndCommitVariantAChanges({
            schedulingOpportunity,
            schedulingOutcome,
          });
        }
      }
    };

    const resetSchedulingOpportunitySelection = (primaryValue: string, secondaryValue?: string) => {
      if (!isVariantB) return;
      const schedulingOpportunity = primaryValue as CallIntelTypes.SchedulingOpportunityEnum;
      if (
        schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED &&
        !secondaryValue
      ) {
        getFieldProps('schedulingOpportunity').onChange({
          name: 'schedulingOpportunity',
          value: CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED,
        });
      }
    };

    const handleSchedulingOutcomeSelection = (e?: FieldChangeEvent) => {
      //@ts-ignore - until FieldChangeEvent is fixed
      const value = e?.target?.value ?? e.value;
      if (isVariantB) {
        if (value === 'none') {
          getFieldProps('schedulingOutcome').onChange({
            name: 'schedulingOutcome',
            value,
          });
          getFieldProps('schedulingOpportunity').onChange({
            name: 'schedulingOpportunity',
            value: CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED,
          });
        } else {
          getFieldProps('schedulingOutcome').onChange({
            name: 'schedulingOutcome',
            value,
          });
          getFieldProps('schedulingOpportunity').onChange({
            name: 'schedulingOpportunity',
            value: CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED,
          });
        }
      } else {
        if (value === 'none') {
          applyAndCommitVariantAChanges({
            schedulingOutcome: value,
            schedulingOpportunity: CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED,
          });
        } else {
          applyAndCommitVariantAChanges({
            schedulingOutcome: value,
            schedulingOpportunity: CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED,
          });
        }
      }
    };

    const updateEditedCallInList = (updatedFields: Partial<CallIntelTypes.Call>, isRestore = false) => {
      const updatedIsFieldEdited = !isRestore
        ? computeIsFieldEdited(
            call.isFieldEdited as CallIntelTypes.IsFieldEdited,
            changedFields as (keyof CallIntelTypes.CallEditableFields)[]
          )
        : undefined;

      setSelectedCall({
        ...selectedCall,
        ...updatedFields,
        ...(!isRestore && { isFieldEdited: updatedIsFieldEdited }),
      } as CallIntelTypes.Call);

      const updatedAllCalls = allCalls.map((item: CallIntelTypes.Call) =>
        item.id === call.id
          ? { ...item, ...updatedFields, ...(!isRestore && { isFieldEdited: updatedIsFieldEdited }) }
          : item
      );

      setAllCalls(updatedAllCalls);

      if (!isRestore) {
        const newEditedCallIds = Array.from(new Set([...editedCallIds, call.id]));
        setEditedCallIds(newEditedCallIds);
      } else {
        setIsRestored(true);
      }
    };

    const showCorrectionBanner = isSkippedOrFailedCall ? false : !isVariantB ? true : canMakeCorrection;

    useEffect(() => {
      if (pendingPayload) {
        submitEditedOuputApi(pendingPayload);
      }
    }, [pendingPayload, submitEditedOuputApi]);

    useEffect(() => {
      if (pendingUpdateCallInList && changedFields && changedFields.length > 0) {
        updateEditedCallInList(pendingUpdateCallInList);
        setPendingUpdateCallInList(null);
        setChangedFields(null);
      }
    }, [changedFields, call.id, pendingUpdateCallInList]);

    useEffect(() => {
      if (isRestored && initialFormState) {
        setCallFormState(initialFormState);
        resetForm();
        setIsRestored(false);
      }
    }, [isRestored, initialFormState]);

    useEffect(() => {
      /**This effect is used to determine if form values changed for Variant B in edit mode */
      if (isVariantB && isEditMode && formValues) {
        const isChanged = computeVariantBChangedPayload(true);
        setIsFormLevelSaveDisabled(!isChanged);
      }
    }, [isVariantB, isEditMode, formValues]);

    useEffect(() => {
      if (!unsavedEditChangesConfig.dontAskAgain && isVariantB && isEditMode) {
        setUnsavedEditChangesConfig({ hasUnsavedChanges: !isFormLevelSaveDisabled });
      }
    }, [isVariantB, isFormLevelSaveDisabled, unsavedEditChangesConfig.dontAskAgain]);

    useEffect(() => {
      const { actionType, hasUnsavedChanges } = unsavedEditChangesConfig;

      if (isVariantB && actionType === 'SAVE' && hasUnsavedChanges && !isFormLevelSaveDisabled) {
        handleFieldSave();
      } else if (isVariantB && actionType === 'CLOSE' && hasUnsavedChanges && !isFormLevelSaveDisabled) {
        handleDiscardChanges(false);
      } else if (isVariantB && actionType === 'DISCARD' && hasUnsavedChanges && !isFormLevelSaveDisabled) {
        handleDiscardChanges();
      }
    }, [unsavedEditChangesConfig]);

    return (
      <>
        <Accordion.Header title={t('Call Analysis')} />
        <Accordion.Body ref={ref} css={styles.accordionBody}>
          {isCallInProcessing ? (
            <AnalyzingCallLoader
              lottie={analyzingCallLottie}
              message={t('Analyzing call recording...')}
              messageCss={styles.loadingMessage}
            />
          ) : (
            <>
              {!(isOnlyPoorAudio && isReviewed) && (
                <CallAnalyzeStatus
                  css={css`
                    margin-bottom: ${theme.spacing(2)};
                  `}
                  isFailedCall={isFailedCall}
                  isPoorAudio={isPoorAudio}
                  isSkippedCall={isSkippedCall}
                  type='analysis'
                />
              )}

              <NakedUl css={styles.list}>
                {isEditAiEnabled && showCorrectionBanner && (
                  <li>
                    <CorrectionBanner
                      canMakeCorrection={canMakeCorrection}
                      isReviewed={isReviewed}
                      showEditHistory={showEditHistory}
                      onMakeCorrection={initiateCorrectionMode}
                      onViewEditHistory={openEditHistoryModal}
                      trackingIds={{
                        correctionBtn: generateTrackingId({
                          component: 'call-analysis',
                          context: 'make-correction-btn-click',
                          isDemoAccount,
                          abTestGroup: isVariantB ? 'design-B' : 'design-A',
                        }),
                        viewHistory: generateTrackingId({
                          component: 'call-analysis',
                          context: 'view-edit-history-btn-click',
                          isDemoAccount,
                          abTestGroup: isVariantB ? 'design-B' : 'design-A',
                        }),
                      }}
                    />
                  </li>
                )}

                <li>
                  <EditableField
                    editMode={isEditMode}
                    label={t('Contact Type')}
                    displayComponent={
                      !isFailedCall && !isSkippedCall ? (
                        <CallIntelChip
                          chipVariants={chipVariants}
                          dataKey={callFormState.contactType}
                          label={dataLabels.contactType?.[callFormState.contactType] ?? t('Unknown')}
                        />
                      ) : (
                        FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CONTACT_TYPE].emptyValue
                      )
                    }
                    editComponent={
                      <RadioBadgeDropdownMenu
                        name='contactType'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        onChange={isVariantB ? getFieldProps('contactType').onChange : handleFieldSave}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'contact-type-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'contact-type-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'contact-type-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.contactType : callFormState.contactType}
                        options={transformOptionsToBadgeOptions(getOptions('contactType'))}
                        widthConfig={{ menuWidth: '180px' }}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isContactTypeEdited : false}
                  />
                </li>

                <li>
                  <EditableField
                    editMode={isEditMode}
                    label={t('Scheduling Opportunity')}
                    displayComponent={
                      !isFailedCall && !isSkippedCall && callFormState.schedulingOpportunity !== undefined ? (
                        <Thumb
                          up={
                            callFormState.schedulingOpportunity ===
                            CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED
                              ? true
                              : false
                          }
                        />
                      ) : (
                        FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY].emptyValue
                      )
                    }
                    editComponent={
                      <NestedBadgeDropdownMenu
                        name='schedulingOpportunity'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        onChange={handleSchedulingOpportunitySelection}
                        onDismiss={isVariantB ? resetSchedulingOpportunitySelection : undefined}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-opportunity-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-opportunity-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-opportunity-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.schedulingOpportunity : callFormState.schedulingOpportunity}
                        subValue={isVariantB ? formValues.schedulingOutcome : callFormState.schedulingOutcome}
                        options={transformOptionsToNestedBadgeOptions(
                          getOptions('schedulingOpportunity', 'record', { withoutLabel: true }),
                          {
                            [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: getOptions(
                              'schedulingOutcome',
                              'record',
                              { withoutLabel: true }
                            ),
                          },
                          {
                            [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]:
                              t('Opportunity'),
                            [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]:
                              t('No Opportunity'),
                            [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: t('Scheduled'),
                            [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: t('Unscheduled'),
                          }
                        )}
                        widthConfig={{ menuWidth: '217px' }}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isSchedulingOpportunityEdited : false}
                    showTooltip={callFormState.schedulingOpportunity === undefined}
                    tooltipText={t('Scheduling Opportunity isn’t analyzed for non-patient calls.')}
                  />
                </li>

                <li>
                  <EditableField
                    editMode={isEditMode}
                    label={t('Scheduling Outcome')}
                    displayComponent={
                      isFailedCall || isSkippedCall || callFormState.schedulingOutcome === undefined ? (
                        DEFAULT_FIELD_EMPTY_VALUE
                      ) : callFormState.schedulingOpportunity ===
                        CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED ? (
                        <Accept
                          accepted={
                            callFormState.schedulingOutcome ===
                            CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED
                              ? true
                              : false
                          }
                        />
                      ) : (
                        FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME].emptyValue
                      )
                    }
                    editComponent={
                      <RadioBadgeDropdownMenu
                        emptyValue={
                          callFormState.schedulingOutcome !== undefined
                            ? FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME].emptyValue
                            : DEFAULT_FIELD_EMPTY_VALUE
                        }
                        name='schedulingOutcome'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-outcome-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-outcome-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'scheduling-outcome-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.schedulingOutcome : callFormState.schedulingOutcome}
                        onChange={handleSchedulingOutcomeSelection}
                        options={transformOptionsToBadgeOptions(
                          getOptions('schedulingOutcome', 'record', { withoutLabel: true, includeEmptyOption: true }),
                          {
                            [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: t('Scheduled'),
                            [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: t('Unscheduled'),
                          }
                        )}
                        widthConfig={{ menuWidth: '217px' }}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isSchedulingOutcomeEdited : false}
                    showTooltip={callFormState.schedulingOutcome === undefined}
                    tooltipText={t('Scheduling Outcome isn’t analyzed for non-patient calls.')}
                  />
                </li>

                <li>
                  <EditableField
                    editMode={isEditMode}
                    label={t('Customer Sentiment')}
                    displayComponent={
                      !isFailedCall && !isSkippedCall && callFormState.callSentiment ? (
                        <Text>
                          {dataLabels.sentimentsWithEmoji?.[callFormState.callSentiment] || callFormState.callSentiment}
                        </Text>
                      ) : (
                        FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SENTIMENT].emptyValue
                      )
                    }
                    editComponent={
                      <RadioBadgeDropdownMenu
                        name='callSentiment'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        onChange={isVariantB ? getFieldProps('callSentiment').onChange : handleFieldSave}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'sentiment-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'sentiment-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'sentiment-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.callSentiment : callFormState.callSentiment}
                        options={transformOptionsToBadgeOptions(getOptions('sentiment'))}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isSentimentEdited : false}
                    showTooltip={!callFormState.callSentiment}
                    tooltipText={t('Customer Sentiment isn’t analyzed for non-patient calls.')}
                  />
                </li>

                <li>
                  <EditableField
                    editMode={isEditMode}
                    label={t('Category')}
                    displayComponent={
                      <div css={styles.chipsWrapper}>
                        {!isFailedCall && !isSkippedCall && callFormState.categories?.length
                          ? callFormState.categories.map((category) => (
                              <CallIntelChip
                                as='category'
                                chipVariants={chipVariants}
                                dataKey={category}
                                label={dataLabels.categories?.[category] || category}
                                key={category}
                              />
                            ))
                          : FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CATEGORY].emptyValue}
                      </div>
                    }
                    editComponent={
                      <ChecklistBadgeDropdownMenu
                        name='categories'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        onChange={isVariantB ? getFieldProps('categories').onChange : handleFieldSave}
                        onSuggestion={openSuggestionModal}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'categories-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'categories-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'categories-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          suggestionBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'categories-suggestion-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.categories : callFormState.categories}
                        options={transformOptionsToBadgeOptions(getOptions('categories'))}
                        required
                        label={t('Category')}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isCategoryEdited : false}
                  />
                </li>

                <li>
                  <EditableField
                    label={t('Appointment Type')}
                    editMode={isEditMode}
                    displayComponent={
                      <div css={styles.chipsWrapper}>
                        {!isFailedCall && !isSkippedCall && callFormState.appointmentTypes?.length
                          ? callFormState.appointmentTypes.map((appointmentType) => (
                              <CallIntelChip
                                as='category'
                                chipVariants={chipVariants}
                                dataKey={appointmentType}
                                label={dataLabels.appointmentTypes?.[appointmentType] || appointmentType}
                                key={appointmentType}
                              />
                            ))
                          : FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE].emptyValue}
                      </div>
                    }
                    editComponent={
                      <ChecklistBadgeDropdownMenu
                        name='appointmentTypes'
                        isLoading={isVariantB ? false : isLoading || isFakeLoading}
                        onChange={isVariantB ? getFieldProps('appointmentTypes').onChange : handleFieldSave}
                        onSuggestion={openSuggestionModal}
                        showSaveChanges={!isVariantB}
                        trackingIds={{
                          fieldId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'appointment-types-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          optionId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'appointment-types-${menuOption}',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          saveBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'appointment-types-save-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                          suggestionBtnId: generateTrackingId({
                            component: 'call-analysis-badge-dropdown',
                            context: 'appointment-types-suggestion-btn-click',
                            isDemoAccount,
                            abTestGroup: isVariantB ? 'design-B' : 'design-A',
                          }),
                        }}
                        useFormLevelSave={isVariantB}
                        value={isVariantB ? formValues.appointmentTypes : callFormState.appointmentTypes}
                        options={transformOptionsToBadgeOptions(
                          getOptions('appointmentTypes', 'record', {
                            extraAppointmentTypes: callFormState.appointmentTypes,
                          })
                        )}
                      />
                    }
                    isEdited={isEditAiEnabled ? call.isFieldEdited?.isAppointmentTypeEdited : false}
                    showTooltip={!callFormState.appointmentTypes?.length}
                    tooltipText={
                      callFormState.contactType !== CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT
                        ? t('No Appointment Type discussed on the call.')
                        : t('Appointment Type isn’t analyzed for non-patient calls.')
                    }
                  />
                </li>

                {isEditAiEnabled && isVariantB && isEditMode && (
                  <li>
                    <div css={styles.actionBtnWrapper}>
                      <Button
                        variant='secondary'
                        onClick={cancelEditMode}
                        trackingId={generateTrackingId({
                          component: 'call-analysis',
                          context: 'cancel-btn-click',
                          isDemoAccount,
                          abTestGroup: isVariantB ? 'design-B' : 'design-A',
                        })}
                      >
                        {t('Cancel')}
                      </Button>
                      <Button
                        css={css`
                          min-width: 120px;
                        `}
                        disabled={isWeaveUser() || isFormLevelSaveDisabled || !isFormComplete}
                        loading={isLoading}
                        variant='primary'
                        trackingId={generateTrackingId({
                          component: 'call-analysis',
                          context: 'save-btn-click',
                          isDemoAccount,
                          abTestGroup: isVariantB ? 'design-B' : 'design-A',
                        })}
                        {...toolTipTriggerProps}
                        onClick={handleFieldSave}
                      >
                        {t('Save Changes')}
                      </Button>
                    </div>
                  </li>
                )}
              </NakedUl>
            </>
          )}
        </Accordion.Body>

        {isEditHistoryOpen && (
          <EditHistoryModal
            modalProps={modalProps}
            onClose={closeEditHistoryModal}
            updateEditedCallInList={updateEditedCallInList}
            isFailedCall={isFailedCall}
            isPoorAudio={isPoorAudio}
            isSkippedCall={isSkippedCall}
          />
        )}

        {suggestionModalConfig.isOpen && (
          <SuggestionModal
            modalProps={modalProps}
            onClose={closeSuggestionModal}
            fieldType={suggestionModalConfig.type}
          />
        )}

        {isWeaveUser() && (
          <Tooltip {...tooltipProps}>
            {t(
              'This feature is intended solely for Weave customers to correct AI outputs perceived as wrongly classified.'
            )}
          </Tooltip>
        )}
      </>
    );
  }
);

CallAnalysis.displayName = 'CallAnalysis';

const styles = {
  accordionBody: css`
    background-color: ${theme.colors.white};
  `,
  list: css`
    li {
      margin-bottom: ${theme.spacing(2)};
    }
    li:last-child {
      margin-bottom: ${theme.spacing(0)};
    }
  `,
  chipsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    justify-content: flex-end;

    @media screen and (max-width: ${breakpoints.small.min}px) {
      justify-content: flex-start;
    }
  `,

  loadingMessage: css`
    margin-top: ${theme.spacing(-3)};
  `,

  actionBtnWrapper: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    justify-content: flex-end;
  `,
};
