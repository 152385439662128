import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Button,
  emptyStateGraphics,
  Text,
  TextLink,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { analyzingCallLottie } from '../../assets';
import { featureFlags } from '../../feature-flags';
import { generateTrackingId } from '../../tracking';
import { callIntelligenceUtils } from '../../utils';
import { AnalyzingCallLoader } from './analyzing-call-loader';
import ServiceQualityDropdownMenu from './badge-dropdown-menu/service-quality-dropdown-menu';
import BetaBadge from './beta-badge';
import { computeIsFieldEdited, SuggestionModalConfig } from './call-analysis';
import { CallAnalyzeStatus } from './call-analyze-status';
import CorrectionBanner from './correction-banner';
import { useCallIntelShallowStore, useCallTakeawayPanelShallowStore } from './hooks';
import EditHistoryModal from './modal/edit-history-modal';
import SuggestionModal from './modal/suggestion-modal';
import { ServiceQualityBadge } from './service-quality-badge';

type CallStatus = {
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
};

type DecoratedFlagDataSummaryProps = {
  flagData: CallIntelTypes.ServiceQualityFlagData[];
  isDemoAccount?: boolean;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
};

type FlagConfig = {
  details: CallIntelTypes.ServiceQualityFlagData[];
  flag: SQFlag;
  isDisabled: boolean;
  isUiDisabled: boolean;
};

type EmptyServiceQualityProps = {
  allFlagsConfig: FlagConfig[];
  call: CallIntelTypes.Call;
  callStatus: CallStatus;
  isDemoAccount?: boolean;
  isLoading: boolean;
  isOnlyPoorAudio: boolean;
  isSkippedOrFailedCall: boolean;
  onFlagChange: (values: SQFlag[]) => void;
  onFlagSave: (values: SQFlag[]) => void;
  onSuggestion: (name: string) => void;
};

export type SQFlag = CallIntelTypes.ServiceQualityFlagEnum;

type ServiceQualityProps = {
  call: CallIntelTypes.Call;
  callStatus: CallStatus;
  isDemoAccount?: boolean;
  isLoading: boolean;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  refetchServiceQuality: () => void;
  serviceQuality?: CallIntelTypes.ServiceQualityResponse;
};

type ViewModeCardProps = {
  allFlagsConfig: FlagConfig[];
  flagConfig: FlagConfig;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  initialFlagConfigs: FlagConfig[];
  isSaveDisabled?: boolean;
  label: string;
  onFlagChange: (values: SQFlag[]) => void;
  onFlagDiscard: () => void;
  onFlagSave: (values: SQFlag[]) => void;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  onSuggestion: (name: string) => void;
};

type EditModeCardProps = {
  allFlagsConfig: FlagConfig[];
  flagConfig: FlagConfig;
  index: number;
  initialFlagConfigs: FlagConfig[];
  isDemoAccount?: boolean;
  isLoading?: boolean;
  isSaveDisabled?: boolean;
  label: string;
  onCancelEditMode: () => void;
  onFlagChange: (values: SQFlag[]) => void;
  onFlagRemove: (value: SQFlag) => void;
  onFlagSave: () => void;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  onSuggestion: (name: string) => void;
};

type ServiceQualityMappingKey = keyof Pick<CallIntelTypes.ServiceQualityResponse, 'issues' | 'resolution'>;

//Constants
const FLAG_ORDER: SQFlag[] = [
  CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
  CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
];

export const serviceQualityMapping: Record<
  ServiceQualityMappingKey,
  Exclude<CallIntelTypes.ServiceQualityFlagEnum, CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN>
> = {
  resolution: CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
  issues: CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
};

// Utility functions

export const formatServiceQualityTime = (timeString: string) => {
  return timeString.split(':').slice(0, 2).join(':');
};

const buildFlagConfigsFromResponse = (
  serviceQuality: CallIntelTypes.ServiceQualityResponse,
  isEditMode = false
): FlagConfig[] => {
  const hasResolution = serviceQuality.resolution.length > 0;
  const hasUnresolved = serviceQuality.issues.length > 0;

  let flagsConfig: FlagConfig[] = [];

  if (hasResolution) {
    flagsConfig.push({
      details: serviceQuality.resolution.filter((detail) => detail.summary?.trim()),
      flag: CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
      isDisabled: serviceQuality.isResolutionDisabled,
      isUiDisabled: false,
    });
  }

  if (hasUnresolved) {
    flagsConfig.push({
      details: serviceQuality.issues.filter((detail) => detail.summary?.trim()),
      flag: CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
      isDisabled: serviceQuality.isIssuesDisabled,
      isUiDisabled: false,
    });
  }

  if (isEditMode) {
    flagsConfig = flagsConfig.filter((flagConfig) => !flagConfig.isDisabled);
  } else {
    flagsConfig.sort((a, b) => FLAG_ORDER.indexOf(a.flag) - FLAG_ORDER.indexOf(b.flag));
    return flagsConfig;
  }

  // For edit mode, include logic for missing flags and "Add Label" row

  const existingFlags = flagsConfig.map((config) => config.flag);
  const missingFlags = FLAG_ORDER.filter((flag) => !existingFlags.includes(flag));

  if (missingFlags.length > 0) {
    flagsConfig.push({
      details: [],
      flag: CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN, // Placeholder for "Add Label"
      isDisabled: false,
      isUiDisabled: false,
    });
  }

  return flagsConfig;
};

const haveFlagsChanged = (initialFlags: FlagConfig[], viewFlags: FlagConfig[]): boolean => {
  if (initialFlags.length !== viewFlags.length) return true;

  const initialMap = new Map(initialFlags.map((flag) => [flag.flag, flag.isDisabled]));
  const viewMap = new Map(viewFlags.map((flag) => [flag.flag, flag.isDisabled]));

  for (const [flag, isDisabled] of initialMap) {
    if (!viewMap.has(flag) || viewMap.get(flag) !== isDisabled) {
      return true;
    }
  }

  return false;
};

const DecoratedFlagDataSummary = ({
  flagData,
  isDemoAccount,
  onSelectingCitationTime,
}: DecoratedFlagDataSummaryProps) => {
  return (
    <>
      {flagData.map((item, idx) => {
        // Removing the final period from the summary if it exists
        const summaryWithoutPeriod = item.summary.replace(/\.$/, '');

        return (
          <Text as='span' key={idx}>
            {summaryWithoutPeriod}{' '}
            <span css={styles.timeText}>
              (
              <TextLink
                css={styles.textLink}
                onClick={() => {
                  !!onSelectingCitationTime && onSelectingCitationTime('', formatServiceQualityTime(item.tsStart));
                }}
                trackingId={generateTrackingId({
                  component: 'service-quality-card',
                  context: 'timestamp-click',
                  isDemoAccount,
                })}
              >
                {formatServiceQualityTime(item.tsStart)}
              </TextLink>
              )
            </span>
            .{' '}
          </Text>
        );
      })}
    </>
  );
};

const DetailLabel = () => {
  const { t } = useTranslation('analytics');

  return (
    <Text css={styles.detailsText} color='subdued'>
      {t('Details')}
    </Text>
  );
};

const EmptyServiceQuality = ({
  allFlagsConfig,
  call,
  callStatus,
  isDemoAccount,
  isLoading,
  isSkippedOrFailedCall,
  onFlagChange,
  onFlagSave,
  onSuggestion,
}: EmptyServiceQualityProps) => {
  const { t } = useTranslation('analytics');

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isEditAiEnabled = getFeatureFlagValue(featureFlags.enableCallIntelEditAi);
  const isVariantB = getFeatureFlagValue(featureFlags.editAiDesignVariant);

  const EmptyStateGraphicNoTasks = emptyStateGraphics.no_service_quality;

  const currentFlags = allFlagsConfig
    .filter((flagConfig) => !flagConfig.isDisabled)
    .map((flagConfig) => flagConfig.flag);

  return (
    <div css={styles.emptyContainer}>
      <CallAnalyzeStatus
        {...callStatus}
        type='analysis'
        css={css`
          width: 100%;
        `}
      />
      <EmptyStateGraphicNoTasks height={200} width={200} />
      <Text css={styles.emptyStateText}>
        {call.contactType !== CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT
          ? t('Nothing to review or spotlight')
          : t('Service Quality isn’t analyzed for non-patient calls.')}
      </Text>

      {isEditAiEnabled && !isVariantB && !isSkippedOrFailedCall && (
        <div css={styles.addLabelWrapper}>
          <Button
            variant='tertiary'
            iconName='add'
            trackingId={generateTrackingId({
              component: 'service-quality',
              context: 'add-label-btn-click',
              isDemoAccount,
              abTestGroup: isVariantB ? 'design-B' : 'design-A',
            })}
          >
            {t('Add Label')}
          </Button>
          <div css={styles.hiddenDropdownWrapper}>
            <ServiceQualityDropdownMenu
              defaultValues={[]}
              disabled={false}
              isLoading={isLoading}
              name='serviceQualityFlags'
              onChange={onFlagChange}
              onSave={onFlagSave}
              onSuggestion={onSuggestion}
              placement='bottom'
              primaryValue=''
              secondaryValues={currentFlags}
              trackingIds={{
                fieldId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                optionId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-${menuOption}',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                saveBtnId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-save-btn-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                suggestionBtnId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-suggestion-btn-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
              }}
              triggerUnsavedChangesModal={false}
              useFormLevelSave={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const ViewModeCard = ({
  allFlagsConfig,
  flagConfig,
  isDemoAccount,
  isLoading,
  initialFlagConfigs,
  isSaveDisabled,
  label,
  onFlagChange,
  onFlagDiscard,
  onFlagSave,
  onSelectingCitationTime,
  onSuggestion,
}: ViewModeCardProps) => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isEditAiEnabled = getFeatureFlagValue(featureFlags.enableCallIntelEditAi);
  const isVariantB = getFeatureFlagValue(featureFlags.editAiDesignVariant);

  const { details: flagData, flag, isDisabled, isUiDisabled } = flagConfig;

  const initialFlags = useMemo(
    () => initialFlagConfigs.filter((flagConfig) => !flagConfig.isDisabled).map((flagConfig) => flagConfig.flag),
    [initialFlagConfigs]
  );

  const currentFlags = allFlagsConfig
    .filter((flagConfig) => !flagConfig.isDisabled)
    .map((flagConfig) => flagConfig.flag);

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div css={styles.serviceQualityCard}>
      {isEditAiEnabled ? (
        <div css={styles.flagHeader}>
          {isVariantB ? (
            <ServiceQualityBadge label={label} size='large' type={flag} weight='bold' disabled={isDisabled} />
          ) : (
            <ServiceQualityDropdownMenu
              defaultValues={initialFlags}
              disabled={isDisabled}
              isLoading={isLoading}
              isPrimaryDisabled={isSaveDisabled}
              name={`serviceQualityFlags-${flag}`}
              onChange={onFlagChange}
              onDiscard={onFlagDiscard}
              onSave={onFlagSave}
              onSuggestion={onSuggestion}
              primaryValue={flag}
              secondaryValues={currentFlags}
              trackingIds={{
                fieldId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                optionId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-${menuOption}',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                saveBtnId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-save-btn-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
                suggestionBtnId: generateTrackingId({
                  component: 'service-quality-badge-dropdown',
                  context: 'service-quality-suggestion-btn-click',
                  isDemoAccount,
                  abTestGroup: isVariantB ? 'design-B' : 'design-A',
                }),
              }}
              useFormLevelSave={false}
            />
          )}
        </div>
      ) : (
        <ServiceQualityBadge label={label} size='large' type={flag} weight='bold' />
      )}

      {!!flagData.length &&
        (isDisabled ? (
          <Button
            css={[styles.detailBtn, styles.detailsText]}
            iconName={!showDetails ? 'caret-down-tiny' : 'caret-up-tiny'}
            trackingId={generateTrackingId({
              component: 'service-quality',
              context: `${!showDetails ? 'show-details' : 'hide-details'}-btn-click`,
              isDemoAccount,
              abTestGroup: isVariantB ? 'design-B' : 'design-A',
            })}
            variant='tertiary'
            onClick={() => setShowDetails((prev) => !prev)}
          >
            {t('{{label}}', { label: !showDetails ? 'Show Details' : 'Hide Details' })}
          </Button>
        ) : (
          <DetailLabel />
        ))}

      <div>
        {isDisabled && !isUiDisabled ? (
          showDetails &&
          flagData.map((item, idx) => (
            <Text as='span' key={idx}>
              {item.summary}
            </Text>
          ))
        ) : (
          <DecoratedFlagDataSummary
            flagData={flagData}
            isDemoAccount={isDemoAccount}
            onSelectingCitationTime={onSelectingCitationTime}
          />
        )}
      </div>

      <div className='service-quality-divider' css={styles.divider} />
    </div>
  );
};

const EditModeCard = ({
  allFlagsConfig,
  flagConfig,
  isDemoAccount,
  index,
  isLoading,
  initialFlagConfigs,
  isSaveDisabled,
  label,
  onCancelEditMode,
  onFlagChange,
  onFlagRemove,
  onFlagSave,
  onSelectingCitationTime,
  onSuggestion,
}: EditModeCardProps) => {
  const { t } = useTranslation('analytics');

  const { details: flagData, flag } = flagConfig;

  const isAddLabelRow = flag === CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN;

  const initialFlags = useMemo(
    () => initialFlagConfigs.filter((flagConfig) => !flagConfig.isDisabled).map((flagConfig) => flagConfig.flag),
    [initialFlagConfigs]
  );

  const currentFlags = allFlagsConfig
    .filter(
      (flagConfig) => flagConfig.flag !== CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN && !flagConfig.isDisabled
    )
    .map((flagConfig) => flagConfig.flag);

  const isLast = index === FLAG_ORDER.length - 1;

  return (
    <div css={styles.serviceQualityCard}>
      {isAddLabelRow ? (
        <div css={styles.editModeCardAddLabelContainer}>
          <div css={styles.editModeCardAddLabelBtnWrapper}>
            <Button
              iconName='add'
              variant='tertiary'
              css={styles.editModeCardAddButton}
              trackingId={generateTrackingId({
                component: 'service-quality',
                context: 'add-label-btn-click',
                isDemoAccount,
                abTestGroup: 'design-B',
              })}
            >
              {t('Add Label')}
            </Button>
            <div css={styles.hiddenDropdownWrapper}>
              <ServiceQualityDropdownMenu
                defaultValues={initialFlags}
                disabled={false}
                isLoading={isLoading}
                name='serviceQualityFlags'
                onChange={onFlagChange}
                onSave={onFlagSave}
                onSuggestion={onSuggestion}
                primaryValue=''
                trackingIds={{
                  fieldId: generateTrackingId({
                    component: 'service-quality-badge-dropdown',
                    context: 'service-quality-click',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  }),
                  optionId: generateTrackingId({
                    component: 'service-quality-badge-dropdown',
                    context: 'service-quality-${menuOption}',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  }),
                  saveBtnId: generateTrackingId({
                    component: 'service-quality-badge-dropdown',
                    context: 'service-quality-save-btn-click',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  }),
                  suggestionBtnId: generateTrackingId({
                    component: 'service-quality-badge-dropdown',
                    context: 'service-quality-suggestion-btn-click',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  }),
                }}
                secondaryValues={currentFlags}
                showSaveChanges={false}
                useFormLevelSave
              />
            </div>
          </div>

          <div css={styles.actionBtnWrapper}>
            <Button
              variant='secondary'
              trackingId={generateTrackingId({
                component: 'service-quality',
                context: 'cancel-btn-click',
                isDemoAccount,
                abTestGroup: 'design-B',
              })}
              onClick={onCancelEditMode}
            >
              {t('Cancel')}
            </Button>
            <Button
              css={styles.saveChangesButton}
              disabled={isWeaveUser() || isSaveDisabled}
              loading={isLoading}
              trackingId={generateTrackingId({
                component: 'service-quality',
                context: 'save-btn-click',
                isDemoAccount,
                abTestGroup: 'design-B',
              })}
              variant='primary'
              onClick={onFlagSave}
            >
              {t('Save Changes')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div css={styles.flagHeader}>
            <div css={styles.editModeCardWrapper}>
              <ServiceQualityBadge label={label} size='large' type={flag} weight='bold' isEditable />
              <Button
                iconName='remove'
                size='small'
                variant='tertiary'
                css={styles.editModeCardRemoveBtn}
                trackingId={generateTrackingId({
                  component: 'service-quality',
                  context: 'remove-label-btn-click',
                  isDemoAccount,
                  abTestGroup: 'design-B',
                })}
                onClick={() => onFlagRemove(flag)}
              />
            </div>
          </div>

          {!!flagData.length && <DetailLabel />}

          <div>
            <DecoratedFlagDataSummary
              flagData={flagData}
              isDemoAccount={isDemoAccount}
              onSelectingCitationTime={onSelectingCitationTime}
            />

            {!isAddLabelRow && isLast && (
              <div css={[styles.actionBtnWrapper, styles.editModeCardActionBtnWrapperSpacing]}>
                <Button
                  variant='secondary'
                  trackingId={generateTrackingId({
                    component: 'service-quality',
                    context: 'cancel-btn-click',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  })}
                  onClick={onCancelEditMode}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  css={styles.saveChangesButton}
                  disabled={isWeaveUser() || isSaveDisabled}
                  loading={isLoading}
                  trackingId={generateTrackingId({
                    component: 'service-quality',
                    context: 'save-btn-click',
                    isDemoAccount,
                    abTestGroup: 'design-B',
                  })}
                  variant='primary'
                  onClick={onFlagSave}
                >
                  {t('Save Changes')}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      <div className='service-quality-divider' css={styles.divider} />
    </div>
  );
};

export const ServiceQuality = ({
  call,
  callStatus,
  isDemoAccount,
  isLoading,
  onSelectingCitationTime,
  refetchServiceQuality,
  serviceQuality,
}: ServiceQualityProps) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { modalProps, triggerProps, closeModal } = useModalControl();

  const {
    allCalls,
    editedCallIds,
    setEditedCallIds,
    setAllCalls,
    selectedCall,
    setSelectedCall,
    unsavedEditChangesConfig,
    setUnsavedEditChangesConfig,
  } = useCallTakeawayPanelShallowStore(
    'allCalls',
    'editedCallIds',
    'setEditedCallIds',
    'setAllCalls',
    'selectedCall',
    'setSelectedCall',
    'unsavedEditChangesConfig',
    'setUnsavedEditChangesConfig'
  );
  const { dataLabels } = useCallIntelShallowStore('dataLabels');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isServiceQualityEnabled = getFeatureFlagValue(featureFlags.enableCallIntelServiceQuality);
  const isEditAiEnabled = getFeatureFlagValue(featureFlags.enableCallIntelEditAi);
  const isVariantB = getFeatureFlagValue(featureFlags.editAiDesignVariant); // true for design B , false for Deisgn A

  const isCallInProcessing = useMemo(() => callIntelligenceUtils.isCallInProcessing(call.status), [call.status]);

  const { isFailedCall, isPoorAudio, isSkippedCall } = callStatus;

  const isFormEdited = !!call?.isFieldEdited?.isServiceQualityFlagEdited;
  const isOnlyPoorAudio = isPoorAudio && !isSkippedCall && !isFailedCall;
  const isSkippedOrFailedCall = isSkippedCall || isFailedCall;

  const [correctionBannerConfig, setCorrectionBannerConfig] = useState({
    canMakeCorrection: isVariantB,
    isReviewed: isFormEdited,
    showEditHistory: isFormEdited,
  });

  const { canMakeCorrection, isReviewed, showEditHistory } = correctionBannerConfig;

  const [isEditMode, setEditMode] = useState(false);
  const [, setIsRestored] = useState(false);
  const [isEditHistoryOpen, setEditHistoryOpen] = useState(false);
  const [suggestionModalConfig, setSuggestionModalConfig] = useState<SuggestionModalConfig>({
    isOpen: false,
    type: '',
  });
  const [pendingUpdateCallInList, setPendingUpdateCallInList] = useState<null | Partial<CallIntelTypes.Call>>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [initialFlags, setInitialFlags] = useState<FlagConfig[]>([]);
  const [viewFlags, setViewFlags] = useState<FlagConfig[]>([]);
  const [editFlags, setEditFlags] = useState<FlagConfig[]>([]);

  const showCorrectionBanner = isSkippedOrFailedCall ? false : !isVariantB ? true : canMakeCorrection;

  const { mutate: submitEditedOuputApi, isLoading: isEditApiLoading } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.editAIOutput : CallIntelligenceApi.noopMutationFn,
    onSuccess: () => {
      if (isVariantB) {
        const filteredEditFlags = editFlags.filter(
          (flag) => flag.flag !== CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN
        );

        let updatedInitialFlags = [
          ...filteredEditFlags.map((flag) => ({
            flag: flag.flag,
            details: flag.details,
            isDisabled: flag.isDisabled,
            isUiDisabled: false,
          })),
          ...initialFlags
            .filter((flag) => !editFlags.some((editFlag) => editFlag.flag === flag.flag))
            .map((flag) => ({
              flag: flag.flag,
              details: flag.details,
              isDisabled: true,
              isUiDisabled: false,
            })),
        ];

        updatedInitialFlags = updatedInitialFlags.sort(
          (a, b) => FLAG_ORDER.indexOf(a.flag) - FLAG_ORDER.indexOf(b.flag)
        );

        setInitialFlags(updatedInitialFlags);
        setEditMode(false);
        setPendingUpdateCallInList({
          serviceQualityFlags: updatedInitialFlags.filter((flag) => !flag.isDisabled).map((flag) => flag.flag),
        });
        setCorrectionBannerConfig((prev) => ({
          ...prev,
          canMakeCorrection: true,
          isReviewed: true,
          showEditHistory: true,
        }));
      } else {
        let newViewFlags = viewFlags.map((flag) => ({ ...flag, isUiDisabled: false }));
        newViewFlags = newViewFlags.sort((a, b) => FLAG_ORDER.indexOf(a.flag) - FLAG_ORDER.indexOf(b.flag));
        setInitialFlags(newViewFlags);
        setPendingUpdateCallInList({
          serviceQualityFlags: newViewFlags.filter((flag) => !flag.isDisabled).map((flag) => flag.flag),
        });
        setCorrectionBannerConfig((prev) => ({
          ...prev,
          canMakeCorrection: false,
          isReviewed: true,
          showEditHistory: true,
        }));
      }
      alert.success(t('Successfully saved changes!'));
    },
    onError: (err) => {
      console.error(err);
      if (http.isHttpError(err)) {
        const errorMessage =
          ((err.data as Record<string, any>)?.code as number) !== 3
            ? t('Unable to save changes. Please try again.')
            : t('Updates were made by another user. Please refresh and try again.');
        alert.error(errorMessage);
      }
    },
    onSettled: () => {},
  });

  const initiateCorrectionMode = () => {
    setEditMode(isVariantB ? true : false);
    setCorrectionBannerConfig((prev) => ({ ...prev, canMakeCorrection: false }));

    if (serviceQuality) {
      const flagConfigs = buildFlagConfigsFromResponse(serviceQuality, true);
      setEditFlags(flagConfigs);
    }
  };

  const openEditHistoryModal = () => {
    setEditHistoryOpen(true);
    triggerProps.onClick();
  };

  const openSuggestionModal = (name: string) => {
    setSuggestionModalConfig({ isOpen: true, type: name });
    triggerProps.onClick();
  };

  const cancelEditMode = () => {
    if (isVariantB) {
      setEditMode(false);
      setCorrectionBannerConfig((prev) => ({ ...prev, canMakeCorrection: true }));
    }
  };

  const closeEditHistoryModal = () => {
    closeModal();
    setEditHistoryOpen(false);
  };

  const closeSuggestionModal = () => {
    closeModal();
    setSuggestionModalConfig({ isOpen: false, type: '' });
  };

  const handleDiscardChanges = (reset = true) => {
    if (isVariantB) {
      if (serviceQuality && reset) {
        const flagConfigs = buildFlagConfigsFromResponse(serviceQuality, true);
        setEditFlags(flagConfigs);
      }
      setUnsavedEditChangesConfig({ hasUnsavedChanges: !reset, actionType: 'NO_ACTION' });
    }
  };

  const computeVariantAChangedPayload = (): CallIntelTypes.EditAIOutputRequest | false => {
    const user = getUser();
    if (!user) return false;

    const currentFields = initialFlags.map((flag) => ({
      serviceQualityFlag: flag.flag,
      isDisabled: flag.isDisabled,
    }));

    const editedFields = viewFlags.map((flag) => ({
      serviceQualityFlag: flag.flag,
      isDisabled: flag.isDisabled,
    }));

    return {
      callId: call?.id as string,
      current_fields: { serviceQualityFlags: currentFields as CallIntelTypes.ServiceQualityFlagState[] },
      edited_fields: { serviceQualityFlags: editedFields as CallIntelTypes.ServiceQualityFlagState[] },
      location_id: call?.locationId as string,
      user_id: user?.userID as string,
    };
  };

  const computeVariantBChangedPayload = (): CallIntelTypes.EditAIOutputRequest | false => {
    const user = getUser();
    if (!user) return false;

    const filteredEditFlags = editFlags.filter(
      (flag) => flag.flag !== CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN
    );

    const filteredInitialFlags = initialFlags.filter((flag) => flag.isDisabled !== true);

    const currentFields = initialFlags.map((flag) => ({
      serviceQualityFlag: flag.flag,
      isDisabled: flag.isDisabled,
    }));

    const editedFields = [
      ...filteredEditFlags.map((flag) => ({
        serviceQualityFlag: flag.flag,
        isDisabled: flag.isDisabled,
      })),
      ...initialFlags
        .filter(
          (flag) => !filteredEditFlags.some((editFlag) => editFlag.flag === flag.flag) // If a flag in initialFlags is missing in editFlags
        )
        .map((flag) => ({
          serviceQualityFlag: flag.flag,
          isDisabled: true, // Set isDisabled to true for missing flags
        })),
    ];

    const hasChanges = haveFlagsChanged(filteredInitialFlags, filteredEditFlags);

    if (hasChanges) {
      return {
        callId: call?.id as string,
        current_fields: {
          serviceQualityFlags: currentFields as CallIntelTypes.ServiceQualityFlagState[],
        },
        edited_fields: {
          serviceQualityFlags: editedFields as CallIntelTypes.ServiceQualityFlagState[],
        },
        location_id: call?.locationId as string,
        user_id: user?.userID as string,
      };
    }

    return false;
  };

  const updateEditedCallInList = (updatedFields: Partial<CallIntelTypes.Call>, isRestore = false) => {
    const updatedIsFieldEdited = !isRestore
      ? computeIsFieldEdited(
          call.isFieldEdited as CallIntelTypes.IsFieldEdited,
          ['serviceQualityFlags'] as (keyof CallIntelTypes.CallEditableFields)[]
        )
      : undefined;

    setSelectedCall({
      ...selectedCall,
      ...updatedFields,
      ...(!isRestore && { isFieldEdited: updatedIsFieldEdited }),
    } as CallIntelTypes.Call);

    const updatedAllCalls = allCalls.map((item) =>
      item.id === call.id
        ? { ...item, ...updatedFields, ...(!isRestore && { isFieldEdited: updatedIsFieldEdited }) }
        : item
    );

    setAllCalls(updatedAllCalls);

    if (!isRestore) {
      const newEditedCallIds = Array.from(new Set([...editedCallIds, call.id]));
      setEditedCallIds(newEditedCallIds);
    } else {
      refetchServiceQuality();
      setIsRestored(true);
    }
  };

  const handleFlagChange = (currentFlags: SQFlag[]) => {
    if (isVariantB) {
      // Variant B: Update editFlags and mark them disabled if exists in currentFlags
      let updatedEditFlags = editFlags.map((flagConfig) => ({
        ...flagConfig,
        isDisabled: !currentFlags.includes(flagConfig.flag),
        isUiDisabled: !currentFlags.includes(flagConfig.flag),
      }));

      // Find new flags that exist in currentFlags but not in editFlags
      const newFlags = currentFlags
        .filter((flag) => !editFlags.some((editFlag) => editFlag.flag === flag))
        .map((flag) => {
          // If flag exists in viewFlags, restore its details
          const existingFlag = viewFlags.find((viewFlag) => viewFlag.flag === flag);
          return {
            details: existingFlag ? existingFlag.details : [], // Restore details if found
            flag,
            isDisabled: false,
            isUiDisabled: true,
          };
        });

      // Combine new flags with updated flags
      updatedEditFlags = [...updatedEditFlags, ...newFlags];

      // Remove unnecessary flags
      updatedEditFlags = updatedEditFlags.filter(
        (flagConfig) =>
          currentFlags.includes(flagConfig.flag) ||
          initialFlags.some((initialFlag) => initialFlag.flag === flagConfig.flag)
      );

      // Check if all necessary flags are present; if so, remove Unknown flag
      const hasAllNecessaryFlags = FLAG_ORDER.every((necessaryFlag) =>
        updatedEditFlags.some((flagConfig) => flagConfig.flag === necessaryFlag)
      );

      if (hasAllNecessaryFlags) {
        updatedEditFlags = updatedEditFlags.filter(
          (flagConfig) => flagConfig.flag !== CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN
        );
      } else {
        // Add Unknown flag if it's missing
        const hasUnknownFlag = updatedEditFlags.some(
          (flagConfig) => flagConfig.flag === CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN
        );

        if (!hasUnknownFlag) {
          updatedEditFlags.push({
            details: [],
            flag: CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN,
            isDisabled: false,
            isUiDisabled: false,
          });
        }
      }

      // Update editFlags state
      setEditFlags(updatedEditFlags);
    }
    // mark existing flags as disabled if not in currentFlags
    else {
      let updatedFlags = viewFlags.map((flagConfig) => ({
        ...flagConfig,
        isDisabled: !currentFlags.includes(flagConfig.flag),
        isUiDisabled: !currentFlags.includes(flagConfig.flag),
      }));

      // find new flags that exist in currentFlags but not in viewFlags
      const newFlags = currentFlags
        .filter((flag) => !viewFlags.some((viewFlag) => viewFlag.flag === flag))
        .map((flag) => ({
          details: [],
          flag,
          isDisabled: false,
          isUiDisabled: true,
        }));

      // add new flags to the top of the updated flags
      updatedFlags = [...newFlags, ...updatedFlags];

      // Remove flags from `viewFlags` that are not in `currentFlags` and do not exist in `initialFlags`
      updatedFlags = updatedFlags.filter(
        (flagConfig) =>
          currentFlags.includes(flagConfig.flag) ||
          initialFlags.some((initialFlag) => initialFlag.flag === flagConfig.flag)
      );
      setViewFlags(updatedFlags);
    }
  };

  const handleFlagSave = () => {
    if (isVariantB) {
      const payload = computeVariantBChangedPayload();
      if (payload) {
        submitEditedOuputApi(payload);
      }
    } else {
      const payload = computeVariantAChangedPayload();
      if (payload) {
        submitEditedOuputApi(payload);
      }
    }
  };

  const handleDiscard = () => {
    setViewFlags(initialFlags);
  };

  const handleFlagRemove = (value: SQFlag) => {
    const updatedFlags = editFlags.filter((flagConfig) => flagConfig.flag !== value);

    // Check if all necessary flags are present
    const hasAllNecessaryFlags = FLAG_ORDER.every((necessaryFlag) =>
      updatedFlags.some((flagConfig) => flagConfig.flag === necessaryFlag)
    );

    if (!hasAllNecessaryFlags) {
      // Add FLAG_UNKNOWN if not all necessary flags are present
      const hasUnknownFlag = updatedFlags.some(
        (flagConfig) => flagConfig.flag === CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN
      );

      if (!hasUnknownFlag) {
        updatedFlags.push({
          details: [],
          flag: CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN,
          isDisabled: false,
          isUiDisabled: false,
        });
      }
    }

    setEditFlags(updatedFlags);
  };

  useEffect(() => {
    if (serviceQuality) {
      const flagConfigs = buildFlagConfigsFromResponse(serviceQuality);
      setInitialFlags(flagConfigs);
    }
  }, [serviceQuality]);

  useEffect(() => {
    if (initialFlags.length) {
      setViewFlags(initialFlags);
    }
  }, [initialFlags]);

  useEffect(() => {
    const isChanged = haveFlagsChanged(initialFlags, viewFlags);
    setIsSaveDisabled(!isChanged);
  }, [viewFlags]);

  useEffect(() => {
    if (pendingUpdateCallInList) {
      updateEditedCallInList(pendingUpdateCallInList);
      refetchServiceQuality();
      setPendingUpdateCallInList(null);
    }
  }, [pendingUpdateCallInList]);

  useEffect(() => {
    if (isVariantB && isEditMode && editFlags) {
      const isChanged = computeVariantBChangedPayload();
      setIsSaveDisabled(!isChanged);
    }
  }, [isVariantB, isEditMode, editFlags, unsavedEditChangesConfig.dontAskAgain]);

  useEffect(() => {
    if (!unsavedEditChangesConfig.dontAskAgain && isVariantB && isEditMode) {
      setUnsavedEditChangesConfig({ hasUnsavedChanges: !isSaveDisabled });
    }
  }, [isVariantB, isSaveDisabled, unsavedEditChangesConfig.dontAskAgain]);

  useEffect(() => {
    const { actionType, hasUnsavedChanges } = unsavedEditChangesConfig;

    if (isVariantB && actionType === 'SAVE' && hasUnsavedChanges && !isSaveDisabled) {
      handleFlagSave();
    } else if (isVariantB && actionType === 'CLOSE' && hasUnsavedChanges && !isSaveDisabled) {
      handleDiscardChanges(false);
    } else if (isVariantB && actionType === 'DISCARD' && hasUnsavedChanges && !isSaveDisabled) {
      handleDiscardChanges();
    }
  }, [unsavedEditChangesConfig]);

  return (
    <>
      <Accordion.Header>
        <div css={styles.betaBadgeWrapper}>
          <Text as='h3' css={styles.title} weight='bold'>
            {t('Service Quality')}
          </Text>
          {isServiceQualityEnabled && <BetaBadge />}
        </div>
      </Accordion.Header>

      <Accordion.Body>
        {isLoading || isCallInProcessing ? (
          <AnalyzingCallLoader
            lottie={analyzingCallLottie}
            message={t('Analyzing call recording...')}
            messageCss={styles.loadingMessage}
          />
        ) : initialFlags?.length === 0 && !isEditMode ? (
          <>
            {isEditAiEnabled && showCorrectionBanner && (
              <div css={styles.correctionBannerWrapper}>
                <CorrectionBanner
                  canMakeCorrection={canMakeCorrection}
                  isReviewed={isReviewed}
                  onMakeCorrection={initiateCorrectionMode}
                  onViewEditHistory={openEditHistoryModal}
                  showEditHistory={showEditHistory}
                  trackingIds={{
                    correctionBtn: generateTrackingId({
                      component: 'service-quality',
                      context: 'make-correction-btn-click',
                      isDemoAccount,
                      abTestGroup: isVariantB ? 'design-B' : 'design-A',
                    }),
                    viewHistory: generateTrackingId({
                      component: 'service-quality',
                      context: 'view-edit-history-btn-click',
                      isDemoAccount,
                      abTestGroup: isVariantB ? 'design-B' : 'design-A',
                    }),
                  }}
                />
              </div>
            )}
            <EmptyServiceQuality
              allFlagsConfig={viewFlags}
              call={call}
              callStatus={callStatus}
              isDemoAccount={!!isDemoAccount}
              isLoading={isLoading || isEditApiLoading}
              isSkippedOrFailedCall={!!isSkippedOrFailedCall}
              isOnlyPoorAudio={!!isOnlyPoorAudio}
              onFlagChange={handleFlagChange}
              onFlagSave={handleFlagSave}
              onSuggestion={openSuggestionModal}
            />
          </>
        ) : (
          <>
            {isEditAiEnabled && showCorrectionBanner && (
              <div css={styles.correctionBannerWrapper}>
                <CorrectionBanner
                  canMakeCorrection={canMakeCorrection}
                  isReviewed={isReviewed}
                  onMakeCorrection={initiateCorrectionMode}
                  onViewEditHistory={openEditHistoryModal}
                  showEditHistory={showEditHistory}
                  trackingIds={{
                    correctionBtn: generateTrackingId({
                      component: 'service-quality',
                      context: 'make-correction-btn-click',
                      isDemoAccount,
                      abTestGroup: isVariantB ? 'design-B' : 'design-A',
                    }),
                    viewHistory: generateTrackingId({
                      component: 'service-quality',
                      context: 'view-edit-history-btn-click',
                      isDemoAccount,
                      abTestGroup: isVariantB ? 'design-B' : 'design-A',
                    }),
                  }}
                />
              </div>
            )}
            {!isEditMode &&
              !!initialFlags.length &&
              viewFlags.map((flagConfig) => (
                <ViewModeCard
                  key={flagConfig.flag}
                  allFlagsConfig={viewFlags}
                  flagConfig={flagConfig}
                  isDemoAccount={isDemoAccount}
                  isLoading={isLoading || isEditApiLoading}
                  initialFlagConfigs={initialFlags}
                  isSaveDisabled={isSaveDisabled}
                  label={dataLabels.serviceQualityFlag?.[flagConfig.flag] ?? flagConfig.flag}
                  onFlagChange={handleFlagChange}
                  onFlagDiscard={handleDiscard}
                  onFlagSave={handleFlagSave}
                  onSelectingCitationTime={onSelectingCitationTime}
                  onSuggestion={openSuggestionModal}
                />
              ))}
            {isEditMode &&
              editFlags.map((flagConfig, idx) => (
                <EditModeCard
                  key={flagConfig.flag}
                  allFlagsConfig={editFlags}
                  flagConfig={flagConfig}
                  isDemoAccount={isDemoAccount}
                  index={idx}
                  isLoading={isLoading || isEditApiLoading}
                  initialFlagConfigs={initialFlags}
                  isSaveDisabled={isSaveDisabled}
                  label={dataLabels.serviceQualityFlag?.[flagConfig.flag] ?? flagConfig.flag}
                  onCancelEditMode={cancelEditMode}
                  onFlagChange={handleFlagChange}
                  onFlagRemove={handleFlagRemove}
                  onFlagSave={handleFlagSave}
                  onSelectingCitationTime={onSelectingCitationTime}
                  onSuggestion={openSuggestionModal}
                />
              ))}
          </>
        )}
      </Accordion.Body>

      {isEditHistoryOpen && (
        <EditHistoryModal
          modalProps={modalProps}
          onClose={closeEditHistoryModal}
          updateEditedCallInList={updateEditedCallInList}
          isFailedCall={isFailedCall}
          isPoorAudio={isPoorAudio}
          isSkippedCall={isSkippedCall}
        />
      )}

      {suggestionModalConfig.isOpen && (
        <SuggestionModal
          modalProps={modalProps}
          onClose={closeSuggestionModal}
          fieldType={suggestionModalConfig.type}
        />
      )}
    </>
  );
};

const styles = {
  addLabelWrapper: css`
    position: relative;
  `,

  hiddenDropdownWrapper: css`
    position: absolute;
    top: 0px;
    width: 100%;
    opacity: 0;
  `,

  correctionBannerWrapper: css`
    margin-bottom: ${theme.spacing(2)};
  `,

  flagHeader: css`
    width: fit-content;
  `,
  serviceQualityCard: css`
    &:last-child .service-quality-divider {
      display: none;
    }
  `,
  divider: css`
    height: ${theme.spacing(0.125)};
    background-color: ${theme.colors.neutral20};
    margin: ${theme.spacing(2, 0)};
  `,
  detailsText: css`
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(0.5)};
  `,
  emptyContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,
  loadingMessage: css`
    margin-top: ${theme.spacing(-3)};
  `,
  timeText: css`
    text-wrap: nowrap;
  `,
  textLink: css`
    cursor: pointer;
  `,
  betaBadgeWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
  title: css`
    font-size: ${theme.fontSize(20)};
  `,
  detailBtn: css`
    color: ${theme.colors.neutral50};
    padding: ${theme.spacing(0)};
    height: ${theme.spacing(3)};
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.fontSize(16)};

    &:active {
      background: none !important;
      color: ${theme.colors.neutral50}!important;

      svg {
        color: ${theme.colors.neutral50}!important;
      }
    }

    &:hover {
      background: none !important;
      color: ${theme.colors.neutral50}!important;
    }

    span {
      flex-direction: row-reverse;

      svg {
        color: ${theme.colors.neutral50};
        width: ${theme.spacing(1)};
        height: ${theme.spacing(1)};
      }
    }
  `,

  actionBtnWrapper: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    justify-content: flex-end;
  `,

  editModeCardAddLabelContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,

  editModeCardAddLabelBtnWrapper: css`
    width: fit-content;
    position: relative;
  `,

  editModeCardAddButton: css`
  padding: ${theme.spacing(0.5)};
  height: ${theme.spacing(3)};
  color: ${theme.colors.neutral70};

  &:active {
    background: ${theme.colors.neutral5}; !important;
    color: ${theme.colors.neutral70}!important;

    svg {
    color: ${theme.colors.neutral70};
  }
  }

  &:hover {
    background: ${theme.colors.neutral5}; !important;
    color: ${theme.colors.neutral70}!important;
  }

  svg {
    color: ${theme.colors.neutral70};
  }
`,

  saveChangesButton: css`
    min-width: 120px;
  `,

  editModeCardWrapper: css`
    display: flex;
    gap: ${theme.spacing(0.5)};
    align-items: center;
  `,

  editModeCardRemoveBtn: css`
  padding: ${theme.spacing(0.5)};
  height: ${theme.spacing(3)};

  &:active {
    background: ${theme.colors.neutral5}; !important;
    color: ${theme.colors.neutral70}!important;

    svg {
    color: ${theme.colors.neutral70};
  }
  }

  &:hover {
    background: ${theme.colors.neutral5}; !important;
    color: ${theme.colors.neutral70}!important;
  }

  svg {
    color: ${theme.colors.neutral70};
  }
`,

  editModeCardActionBtnWrapperSpacing: css`
    margin-top: ${theme.spacing(2)};
  `,
};
