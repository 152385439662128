import React, { useEffect, useState } from 'react';
import { Notification, Subscriber } from 'sip.js';
import { createContext, useContextSelector } from 'use-context-selector';
import { RemoteParty } from '../types';
import { makeTargetURI } from '../utils/phone-utils';
import { useSoftphoneClient } from './softphone-client-provider';
import { useSoftphoneParkSlots } from './softphone-park-slots-provider';
import { useSoftphoneUsers } from './softphone-users-provider';

type SoftphoneSubscriptionsContext = Record<string, never>;
const SoftphoneSubscriptionsContext = createContext({} as SoftphoneSubscriptionsContext);

type Props = {
  children: React.ReactNode;
};
export const SoftphoneSubscriptionsProvider = ({ children }: Props) => {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const client = useSoftphoneClient((ctx) => ctx.client);
  const isRegistered = useSoftphoneClient((ctx) => ctx.registrationState === 'Registered');
  const users = useSoftphoneUsers((ctx) => ctx.users);
  // const setUserPresence = useSoftphoneUsers((ctx) => ctx.setUserPresence);
  // const clearUserPresence = useSoftphoneUsers((ctx) => ctx.clearPresence);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlots);
  const setParkSlotPresence = useSoftphoneParkSlots((ctx) => ctx.setParkSlotPresence);
  const clearParkSlotPresence = useSoftphoneParkSlots((ctx) => ctx.clearPresence);

  /**
   * Resubscribe to each park slot and user every time registration state changes, or users/park slots change
   * If this proves to be expensive, we can tighten this up, but these things shouldn't generally change after the first render
   */
  useEffect(() => {
    subscribers.forEach((subscription) => {
      subscription.unsubscribe();
      subscription.dispose();
    });
    setSubscribers([]);

    if (!isRegistered || !client) {
      return;
    }

    // clearUserPresence();
    // const userSubscribers = users.map((user) => {
    //   const subscriber = new Subscriber(client.userAgent, makeTargetURI(user.presenceUri ?? ''), 'dialog');
    //   subscriber.delegate = {
    //     onNotify: (notification) => {
    //       const info = getNotificationInfo(notification);
    //       if (info?.state !== 'terminated') {
    //         // console.log({ uri: info?.uri, notification, info });
    //       }
    //       if (info?.uri) {
    //         notification.accept();
    //         setUserPresence(info?.uri, info.state === 'confirmed' ? 'occupied' : 'vacant');
    //       }
    //     },
    //   };
    //   subscriber.subscribe();
    //   return subscriber;
    // });

    clearParkSlotPresence();
    const parkSlotSubscribers = parkSlots.map((slot) => {
      const subscriber = new Subscriber(client.userAgent, makeTargetURI(slot.uri), 'dialog');
      subscriber.delegate = {
        onNotify: (notification) => {
          const info = getNotificationInfo(notification);
          if (!info?.uri && !info?.xml) {
            return;
          }
          notification.accept();
          const remoteParty = getRemotePartyInfo(info.xml);
          const status = info.state === 'confirmed' ? 'occupied' : 'vacant';
          if (status === 'occupied' && remoteParty) {
            setParkSlotPresence(info?.uri, {
              status,
              startedAt: new Date(),
              remoteParty,
            });
          } else {
            setParkSlotPresence(info?.uri, { status: 'vacant' });
          }
        },
      };
      subscriber.subscribe();
      return subscriber;
    });

    setSubscribers([...parkSlotSubscribers]);
  }, [isRegistered, users, client]);

  const value = {} satisfies SoftphoneSubscriptionsContext;

  return <SoftphoneSubscriptionsContext.Provider value={value}>{children}</SoftphoneSubscriptionsContext.Provider>;
};

export const useSoftphoneSubscriptions = <T extends any>(selector: (value: SoftphoneSubscriptionsContext) => T) => {
  return useContextSelector(SoftphoneSubscriptionsContext, selector);
};

type NotificationState = 'terminated' | 'confirmed';
const getNotificationInfo = (notification: Notification) => {
  const body = notification.request.body;
  const xml = new DOMParser().parseFromString(body, 'text/xml');
  const state = xml.getElementsByTagName('state')[0].childNodes[0].nodeValue as NotificationState;
  const uri = xml.getElementsByTagName('dialog-info')?.item(0)?.getAttribute('entity')?.replace('sip:', '');

  if (!uri) {
    console.warn('Received Notify, username not found');
    return;
  }
  if (!state) {
    console.warn('Received Notify, no state given');
    return;
  }

  return {
    uri,
    state,
    xml,
  };
};

const getRemotePartyInfo = (xml: Document) => {
  const remoteParty = xml.getElementsByTagName('remote')?.item(0);
  const identity = remoteParty?.getElementsByTagName('identity')?.item(0)?.getAttribute('display');
  const uri = remoteParty?.getElementsByTagName('target')?.item(0)?.getAttribute('uri');
  if (!uri) {
    return null;
  }
  return {
    displayName: identity ?? 'unknown',
    uri,
  } as const satisfies RemoteParty;
};
