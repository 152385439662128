import { useNotificationContext } from '@frontend/notifications';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useScopedAppFlagStore } from '@frontend/scope';
import { usePhoneOverrideShallowStore, usePhoneOverrideShallowStoreV2 } from '@frontend/shared';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { NotificationsDisabledGlobalBanner } from './notifications-disabled';
import { OverrideNotificationRibbon } from './override-notification';
import { SoftphoneInAnotherTabRibbon } from './softphone-in-another-tab';

export const RibbonManager = () => {
  const isActiveTab = useSoftphoneManager((ctx) => ctx.isActiveTab);
  const activeTab = useSoftphoneManager((ctx) => ctx.activeTab);
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const { maxLocationsSelected } = useNotificationContext();
  const { overrides } = usePhoneOverrideShallowStore('overrides');
  const { overridesV2 } = usePhoneOverrideShallowStoreV2('overridesV2');
  const phoneOverrides = overridesV2.phoneOverrides;
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasCallRoutesFlag = getFeatureFlagValue('call-routes-settings');
  const enabledOverrides = hasCallRoutesFlag
    ? phoneOverrides?.filter((override) => override.enabled) || []
    : overrides?.filter((override) => override.enabled) || [];

  return (
    <div>
      {maxLocationsSelected && <NotificationsDisabledGlobalBanner />}
      {phoneConfig?.deviceType === 'DEVICE_TYPE_SOFTPHONE' && activeTab && !isActiveTab && (
        <SoftphoneInAnotherTabRibbon />
      )}
      {enabledOverrides.length > 0 && <OverrideNotificationRibbon />}
    </div>
  );
};
