import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useMerchant } from '@frontend/payments-hooks';
import { useAppFlagStore } from '@frontend/shared';
import { PAYMENTS_HVA_FF } from '../constants';

interface UseHVAPaymentsReturnData {
  hasAccountCreationPermission: boolean;
  hasPaymentsAccount: boolean;
  showPromotion: boolean;
  isLoadingMerchant: boolean;
}

// High Value Adoption Payments Hook
export const useHVAPayments = ({ locationId }: { locationId: string }): UseHVAPaymentsReturnData => {
  const { getFeatureFlagValue, getCustomizationFlagValue } = useAppFlagStore();

  const isPaymentsHVAFFEnabled = getFeatureFlagValue({
    locationIds: [locationId],
    flagName: PAYMENTS_HVA_FF,
  });

  const isWeavePayActive = getCustomizationFlagValue({
    locationIds: [locationId],
    flagName: 'weavepay',
    strategy: 'ALL',
  });

  const shouldLoadMerchant = isPaymentsHVAFFEnabled && isWeavePayActive;

  const { isLoading: isLoadingMerchant, merchantsData } = useMerchant({
    allLocations: false,
    shouldRun: shouldLoadMerchant,
    locationId: locationId,
  });

  const hasAccountCreationPermission = hasSchemaACL(locationId, Permission.BILLING_INFORMATION_WRITE);
  const isMerchantDataFetched = merchantsData?.[locationId]?.merchantFetched;
  const hasPaymentsAccount = !!merchantsData?.[locationId]?.merchant;
  const showPromotion = shouldLoadMerchant && !isLoadingMerchant && isMerchantDataFetched && !hasPaymentsAccount;

  return {
    hasAccountCreationPermission,
    hasPaymentsAccount,
    showPromotion,
    isLoadingMerchant,
  };
};
