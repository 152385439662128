import { useMemo } from 'react';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import { formatDate } from '@frontend/date';
import { useAppScopeStore } from '@frontend/scope';
import { useGetCalendarEventsV3Data } from '../../hooks';
import { CalendarEventsV3DateFormat } from '../../utils';
import { convertV3CalendarEventToAppointmentDetails } from '../../views/CalendarV3/helpers';

export const useGetAppointments = (startDate: string, endDate: string) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const appointmentListV2Query = ServiceProvidersQueries.useListAppointments({
    startDate,
    endDate,
    locationIds: selectedLocationIds,
    enabled: !isScheduleV3FlagEnabled,
  });

  const appointmentListV3Query = useGetCalendarEventsV3Data({
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedLocationIds,
    request: {
      startDateTime: formatDate(startDate, CalendarEventsV3DateFormat, true),
      endDateTime: formatDate(endDate, CalendarEventsV3DateFormat, true),
    },
    enabled: isScheduleV3FlagEnabled,
  });

  const formattedAppointmentListV3Data = useMemo(() => {
    return convertV3CalendarEventToAppointmentDetails(appointmentListV3Query.data?.events ?? []);
  }, [appointmentListV3Query.data?.events]);

  const isLoading = appointmentListV2Query.isFetching || appointmentListV3Query.isFetching;

  const data = isScheduleV3FlagEnabled
    ? formattedAppointmentListV3Data
    : appointmentListV2Query.data?.appointments ?? [];

  return { appointments: data, isLoading };
};
