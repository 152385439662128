import { memo, useEffect, useMemo } from 'react';
import { TaskType } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { getFilteredEnumValues } from '../../utils';
import { SummaryCardClickHandler } from './call-intel-summary-charts';
import { CallIntelLocationComparisonStackChart, OpportunityConversionLeaderboard } from './charts';
import { FollowUpReasonChart } from './charts/follow-up-reason-chart';
import { CallIntelMockData } from './demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from './hooks';
import { OpportunityLegendContent } from './opportunity-legend-content';

type Props = {
  betweenLocations?: boolean;
  drillDownFilters?: CallIntelTypes.DrillDownOptions;
  internalPage?: boolean;
  onChangeLoadingState?: (isLoading: boolean) => void;
  onClickSummaryCard?: SummaryCardClickHandler;
};

function formatInferencesChartData<T>(
  data: { [key: string]: CallIntelTypes.LocationStat<T> } | undefined,
  locations: Record<string, string>,
  prefillHelper: string[],
  isDemoAccount?: boolean
) {
  return Object.entries(data ?? {}).reduce((acc, [locationId, { inferences }]) => {
    return {
      ...acc,
      [locations[isDemoAccount ? `demo-${locationId}` : locationId] || locationId]: prefillHelper.reduce(
        (acc, inference) => ({ ...acc, [inference]: (inferences as { [key: string]: number })[inference] }),
        {}
      ),
    };
  }, {});
}

function formatOpportunitiesChartData(
  data: { [key: string]: CallIntelTypes.OpportunitiesByLocation } | undefined,
  locations: Record<string, string>,
  sentimentsByLocation: Record<string, Record<string, number>>,
  isDemoAccount?: boolean
) {
  return Object.entries(data ?? {}).reduce(
    (acc, [locationId, { unscheduledOpportunities, totalOpportunities }]) => {
      const locationName = locations[isDemoAccount ? `demo-${locationId}` : locationId] || locationId;

      const totalAnalysedCalls = Object.values(sentimentsByLocation[locationName] || {}).reduce(
        (acc, value) => acc + (value ?? 0),
        0
      );

      const opportunities = {
        scheduledOpportunities: totalOpportunities - unscheduledOpportunities,
        unscheduledOpportunities,
        noOpportunities: totalAnalysedCalls - totalOpportunities,
      };

      const leaderboard = {
        ...opportunities,
        totalAnalysedCalls,
        totalOpportunities,
      };

      return {
        opportunities: {
          ...acc.opportunities,
          [locationName]: opportunities,
        },
        leaderboard: {
          ...acc.leaderboard,
          [locationId]: leaderboard,
        },
      };
    },
    {
      opportunities: {},
      leaderboard: {},
    }
  );
}

const getAllInferencesList = (
  data:
    | CallIntelTypes.LocationsStats['appointmentTypesByLocation']
    | CallIntelTypes.LocationsStats['categoriesByLocation']
    | undefined
) => {
  const values = Object.values(data || {});
  const maxInferencesIndex = values
    .map(({ inferences }) => Object.keys(inferences).length)
    .reduce((maxIndex, currentValue, currentIndex, array) => {
      return currentValue > array[maxIndex] ? currentIndex : maxIndex;
    }, 0);

  return Object.keys(values[maxInferencesIndex]?.inferences || {}).sort();
};

export const CallIntelLocationComparisonCharts = memo(
  ({ betweenLocations, drillDownFilters, internalPage, onChangeLoadingState, onClickSummaryCard }: Props) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const { dataLabels, filters, isDemoAccount } = useCallIntelShallowStore('dataLabels', 'filters', 'isDemoAccount');
    const { locations } = useCallIntelLocations({
      demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
    });
    const drillDownKey = drillDownFilters?.key || '';
    const drillDownValue = drillDownFilters?.value?.[0] || '';

    const { lastUsedVertical: vertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

    const demoCallRecords = useMemo(() => {
      if (drillDownKey === 'appointment_types') {
        return CallIntelMockData.locationsComparisonStats({
          appointmentType: drillDownValue as CallIntelTypes.AppointmentTypeEnum,
          locations: filters.locations,
          vertical,
        });
      } else if (drillDownKey === 'categories') {
        return CallIntelMockData.locationsComparisonStats({
          category: drillDownValue as CallIntelTypes.CategoryEnum,
          locations: filters.locations,
          vertical,
        });
      } else if (drillDownKey === 'sentiments') {
        return CallIntelMockData.locationsComparisonStats({
          sentiment: drillDownValue as CallIntelTypes.SentimentEnum,
          locations: filters.locations,
          vertical,
        });
      } else {
        return CallIntelMockData.locationsComparisonStats({
          locations: filters.locations,
          vertical,
        });
      }
    }, [isDemoAccount, vertical]);

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.callIntelligence(`locations-stats-${JSON.stringify(filters)}`),
      queryFn: () =>
        isDemoAccount ? null : CallIntelligenceApi.getStatsByLocations({ drillDownOptions: drillDownFilters, filters }),
      onError: (err) => {
        alert.error(t('Failed to fetch location comparison data'));
        console.error(err);
      },
      refetchOnWindowFocus: false,
      select: (data) => (isDemoAccount ? demoCallRecords : data),
      staleTime: 1000 * 60 * 5,
    });

    const chartData = useMemo(() => {
      const sentimentsByLocation = formatInferencesChartData(
        data?.sentimentsByLocation,
        locations,
        [
          CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE,
          CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL,
          CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE,
        ],
        isDemoAccount
      );

      return {
        appointmentTypesByLocation: formatInferencesChartData(
          data?.appointmentTypesByLocation,
          locations,
          getAllInferencesList(data?.appointmentTypesByLocation),
          isDemoAccount
        ),
        categoriesByLocation: formatInferencesChartData(
          data?.categoriesByLocation,
          locations,
          getAllInferencesList(data?.categoriesByLocation),
          isDemoAccount
        ),
        sentimentsByLocation,
        taskTypesByLocation: formatInferencesChartData(
          data?.taskTypesByLocation,
          locations,
          getFilteredEnumValues(TaskType, TaskType.TYPE_UNKNOWN),
          isDemoAccount
        ),
        opportunitiesByLocation: formatOpportunitiesChartData(
          data?.opportunitiesByLocation,
          locations,
          sentimentsByLocation,
          isDemoAccount
        ),
      };
    }, [data, locations]);

    const opportunitiesChartTitle = useMemo(() => {
      return drillDownKey
        ? t('{{value}} Opportunities', {
            value:
              drillDownKey === 'appointment_types'
                ? dataLabels.appointmentTypes?.[drillDownValue]
                : drillDownKey === 'categories'
                ? dataLabels.categories?.[drillDownValue]
                : dataLabels.sentiments?.[drillDownValue],
          })
        : t('Opportunity Conversion Leaderboard');
    }, [drillDownKey, drillDownValue]);

    useEffect(() => {
      onChangeLoadingState?.(isLoading);
    }, [isLoading]);

    return (
      <>
        {internalPage ? (
          <CallIntelLocationComparisonStackChart
            categorisedComparison={betweenLocations}
            colors={{
              noOpportunities: theme.colors.neutral50,
              scheduledOpportunities: theme.colors.success40,
              unscheduledOpportunities: theme.colors.warning50,
            }}
            data={chartData.opportunitiesByLocation.opportunities}
            internalPage
            isLoading={isLoading}
            labels={{
              noOpportunities: (
                <OpportunityLegendContent iconName='feedback-bad-small' label={t('No Opportunity')} variant='neutral' />
              ),
              scheduledOpportunities: (
                <OpportunityLegendContent iconName='check-small' label={t('Scheduled')} variant='success' />
              ),
              unscheduledOpportunities: (
                <OpportunityLegendContent iconName='x-small' label={t('Unscheduled')} variant='warn' />
              ),
            }}
            title={opportunitiesChartTitle}
          />
        ) : (
          <OpportunityConversionLeaderboard
            categorisedComparison={betweenLocations}
            data={chartData.opportunitiesByLocation.leaderboard}
            isLoading={isLoading}
            onClick={onClickSummaryCard}
            title={opportunitiesChartTitle}
          />
        )}

        <FollowUpReasonChart
          categorisedComparison={betweenLocations}
          data={chartData.taskTypesByLocation}
          isLoading={isLoading}
          title={t('Follow-up Tasks by Reason')}
        />

        {drillDownKey !== 'categories' && (
          <CallIntelLocationComparisonStackChart
            categorisedComparison={betweenLocations}
            data={chartData.categoriesByLocation}
            dataType='category'
            isLoading={isLoading}
            internalPage={internalPage}
            title={
              drillDownKey
                ? t('{{value}} Categories', {
                    value:
                      drillDownKey === 'appointment_types'
                        ? dataLabels.appointmentTypes?.[drillDownValue]
                        : dataLabels.sentiments?.[drillDownValue],
                  })
                : t('Calls by Category')
            }
          />
        )}

        {drillDownKey !== 'appointment_types' && (
          <CallIntelLocationComparisonStackChart
            categorisedComparison={betweenLocations}
            data={chartData.appointmentTypesByLocation}
            dataType='appointment-type'
            isLoading={isLoading}
            internalPage={internalPage}
            title={
              drillDownKey
                ? t('{{value}} Appointment Types', {
                    value:
                      drillDownKey === 'categories'
                        ? dataLabels.categories?.[drillDownValue]
                        : dataLabels.sentiments?.[drillDownValue],
                  })
                : t('Calls by Appointment Type')
            }
          />
        )}

        {drillDownKey !== 'sentiments' && (
          <CallIntelLocationComparisonStackChart
            categorisedComparison={betweenLocations}
            data={chartData.sentimentsByLocation}
            dataType='sentiment'
            isLoading={isLoading}
            internalPage={internalPage}
            title={
              drillDownKey
                ? t('{{value}} Customer Sentiment', {
                    value:
                      drillDownKey === 'appointment_types'
                        ? dataLabels.appointmentTypes?.[drillDownValue]
                        : dataLabels.categories?.[drillDownValue],
                  })
                : t('Customer Sentiment')
            }
          />
        )}
      </>
    );
  }
);

CallIntelLocationComparisonCharts.displayName = 'CallIntelLocationComparisonCharts';
