import { useMemo } from 'react';
import dayjs from 'dayjs';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SkeletonLoader, Text } from '@frontend/design-system';
import { useGetCalendarEventsV3Data, useSchedulingLocationInfo } from '../hooks';
import { getCalendarStartAndEndDateTime } from '../utils/date-helpers';

const MAX_APPOINTMENTS = 999;

/**
 * @dashboard-widget
 *
 * id: appointment-count
 * title: Appointments
 * description: Show number of appointments scheduled for the day.
 * icon: calendar-small
 */
export const AppointmentCountWidget: DashboardWidgetFC = () => {
  const { selectedLocationIds } = useSchedulingLocationInfo();
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();
  const { t } = useTranslation('dashboard');
  const { startDate, endDate } = useMemo(() => {
    const startDate = dayjs().startOf('day').toISOString();
    const endDate = dayjs().endOf('day').toISOString();
    return { startDate, endDate };
  }, []);

  const { data: appointmentsV2, isFetching: isLoadingV2Data } = ServiceProvidersQueries.useListAppointments({
    startDate,
    endDate,
    locationIds: selectedLocationIds,
    enabled: !isScheduleV3FlagEnabled,
  });

  const v3Dates = getCalendarStartAndEndDateTime();

  const { data: calendarEventsV3, isFetching: isLoadingV3Data } = useGetCalendarEventsV3Data({
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedLocationIds,
    request: {
      startDateTime: v3Dates.startDateTime,
      endDateTime: v3Dates.endDateTime,
      limit: 1,
      page: 1,
    },
    enabled: isScheduleV3FlagEnabled,
  });

  const appointmentCount =
    (isScheduleV3FlagEnabled ? calendarEventsV3?.totalCount : appointmentsV2?.appointments?.length) ?? 0;
  const isLoading = isLoadingV2Data || isLoadingV3Data;

  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content>
        {isLoading ? (
          <SkeletonLoader height={48} width='100%' />
        ) : (
          <Text weight='bold' css={{ fontSize: theme.fontSize(48), lineHeight: 1 }}>
            {appointmentCount > MAX_APPOINTMENTS ? `${MAX_APPOINTMENTS}+` : appointmentCount}
          </Text>
        )}
        <Text size='medium' color='subdued'>
          {t('Today')}
        </Text>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

AppointmentCountWidget.config = {
  size: 'tiny-slim',
  feature: 'schedule',
};
