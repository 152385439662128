import { useQuery, useQueryClient } from 'react-query';
import {
  getProvidersExceptionsForMultipleProvidersOfMultiLocations,
  getProvidersOfficeTimesForMultipleProviders,
  getProvidersExceptionsForMultipleProviders,
  listAppointments,
  SchemaScheduleListAppointments,
} from './api';
import { ONE_MINUTE_IN_MILLISECONDS } from './constants';
import { queryKeys } from './query-keys';
import {
  CalendarExceptionForMultipleProviders,
  ProvidersExceptionsForMultipleProvidersOfMultiLocations,
  ProvidersOfficeHoursByDayOfWeekForMultipleProviders,
} from './types';

export const useGetProvidersExceptionsForMultipleProviders = (req: CalendarExceptionForMultipleProviders['input']) => {
  const queryKeys = [
    'providers-exceptions',
    ...(req.providerIds ?? []),
    req.startDateTime ?? '',
    req.endDateTime ?? '',
  ];
  return useQuery({
    queryKey: queryKeys,
    queryFn: () => getProvidersExceptionsForMultipleProviders(req),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!req.providerIds?.length && !!req.locationId,
  });
};

type ProviderOfficeHoursForMultiProvidersRequest = ProvidersOfficeHoursByDayOfWeekForMultipleProviders['input'] & {
  enabled?: boolean;
};

export const useGetProvidersOfficeHoursForMultipleProviders = (req: ProviderOfficeHoursForMultiProvidersRequest) => {
  const queryKeys = ['providers-office-hours', ...(req.providerId ?? []), req.dayOfWeek];
  const enabled = req.enabled ?? true;
  return useQuery({
    queryKey: queryKeys,
    queryFn: () => getProvidersOfficeTimesForMultipleProviders(req),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!req.providerId?.length && !!req.dayOfWeek && enabled,
  });
};

export const useGetProvidersExceptionsForMultipleProvidersOfMultiLocations = (
  req: ProvidersExceptionsForMultipleProvidersOfMultiLocations['input']
) => {
  return useQuery({
    queryKey: queryKeys.providerExceptions(req),
    queryFn: () => getProvidersExceptionsForMultipleProvidersOfMultiLocations(req),
    enabled: !!(req.providerIds?.length && req.locationIds?.length),
  });
};

type ListAppointmentsRequest = {
  startDate: string;
  endDate: string;
  locationIds: string[];
  enabled?: boolean;
};

export const useListAppointments = ({ startDate, endDate, locationIds, enabled = true }: ListAppointmentsRequest) => {
  const request: SchemaScheduleListAppointments['input'] = {
    between: { start: startDate, end: endDate },
    equals: { locationIds },
  };

  return useQuery({
    queryKey: queryKeys.appointments(request),
    queryFn: () => listAppointments(request),
    retry: 1,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: !!locationIds.length && !!startDate && !!endDate && enabled,
  });
};

// Note: this hook is invalidating appointment list cache which mostly used in widgets + messaging settings
export const useInvalidateListAppointments = () => {
  const queryClient = useQueryClient();
  const apptListQueryKeys = queryKeys.appointments({});

  return () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === apptListQueryKeys[0] && query.queryKey[1] === apptListQueryKeys[1],
    });
  };
};
