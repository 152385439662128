import { useEffect } from 'react';
import { DeviceType } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { OverrideApi } from '@frontend/api-overrides';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { useQueryDevices, registerPhone } from '@frontend/api-phone-sync';
import { getUser } from '@frontend/auth-helpers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { CallPopInterface } from '@frontend/pop';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore, useScopedAppFlagStore, useScopedQuery } from '@frontend/scope';
import { usePhoneOverrideShallowStore, usePhoneOverrideShallowStoreV2 } from '@frontend/shared';
import { shell, IPCRendererCallback, IPCEventName } from '@frontend/shell-utils';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { queryKeys } from '.';

/**
 * This hook is run on app load to initialize the phone config
 *
 * We query the list of saved devices and find the active one for the current user.
 * This device will be readable from the phone config store.
 */
export const useInitializePhones = ({ ready }: { ready: boolean }) => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const phoneTenantId = accessibleLocationData[selectedLocationIds[0]]?.phoneTenantId;
  const { setPhoneConfig } = usePhoneConfigShallowStore('setPhoneConfig');
  const { setOverrides } = usePhoneOverrideShallowStore('setOverrides');
  const { setOverridesV2 } = usePhoneOverrideShallowStoreV2('setOverridesV2');
  const user = getUser();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasCallRoutesFlag = getFeatureFlagValue('call-routes-settings');

  const { data: devices } = useQueryDevices(selectedLocationIds, {
    enabled: ready,
    select: (data) => data?.filter((device) => device.availableInContext),
  });

  useEffect(() => {
    if (devices && devices.length > 0) {
      const foundPhoneConfig = devices.find((device) => device.activeUserId === user?.userID);
      if (foundPhoneConfig?.availableInContext) {
        setPhoneConfig(foundPhoneConfig);
      } else if (foundPhoneConfig?.deviceType !== DeviceType.DEVICE_TYPE_DESK_PHONE && foundPhoneConfig?.sipProfileId) {
        registerPhone(foundPhoneConfig?.sipProfileId);
      } else {
        setPhoneConfig(undefined);
      }
    }
  }, [devices]);

  const { data: overrides } = useScopedQuery({
    queryKey: ['phone', 'listOverride'],
    queryFn: async () => {
      const listOverridesResponse = await OverrideApi.listOverride(
        { locationIds: selectedLocationIds },
        { locationId: selectedLocationIds[0] }
      );
      return listOverridesResponse.phoneOverrides ?? [];
    },
    enabled: selectedLocationIds.length > 0 && !hasCallRoutesFlag,
    refetchInterval: 60 * 1000,
  });

  const { data } = useQuery({
    queryKey: [phoneTenantId, ...queryKeys.settings.listOverrides()],
    queryFn: async () => PhoneOverrideApi.List({ tenantId: phoneTenantId ?? '' }),
    enabled: selectedLocationIds.length > 0 && hasCallRoutesFlag,
    staleTime: 0,
    refetchInterval: 60 * 1000,
  });

  useEffect(() => {
    if (overrides) {
      setOverrides(overrides);
    }
  }, [overrides]);

  useEffect(() => {
    if (data) {
      setOverridesV2(data);
    }
  }, [data]);

  useInitializeShellForCallPops();
};

const useInitializeShellForCallPops = () => {
  const { _inspect, _navigateToIndex, _dismiss } = CallPopInterface;
  const { show } = useSlidePanelShallowStore('show');

  useEffect(() => {
    if (!shell.isShell) {
      return;
    }

    const callback: IPCRendererCallback<IPCEventName.PopMain> = (_e, data) => {
      if (data.type === 'inspect') {
        _inspect(data.notification);
      }

      if (data.type === 'navigate') {
        _navigateToIndex(data.index);
      }

      if (data.type === 'dismiss') {
        const next = _dismiss(data.id);
        /**
         * Post dismiss, show the next available contact in the slide panel if it is open.
         */
        if (next && show) {
          _inspect(next);
        }
      }
    };
    const id = 'initialize-shell-for-call-pops' + Math.random().toString();
    shell.on?.(IPCEventName.PopMain, callback, id);

    return () => {
      if (callback && id) {
        shell.off?.(IPCEventName.PopMain, callback, id);
      }
    };
  }, []);
};
