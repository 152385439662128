import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { convertToSystemTimezone } from '../../utils';

export const convertV3CalendarEventToAppointmentDetails = (events: CalendarEvent[]): Appointment[] => {
  return events.map((event: CalendarEvent) => {
    // TODO - This is a temporary fix to handle the timezone issue. Need to refactor this code.
    const startDateTime = convertToSystemTimezone({
      dateString: event.startDate || '',
      timeString: event.startTime || '',
      sourceTimezone: 'UTC',
    }).toString();

    return {
      id: event.id,
      locationIds: event.locationId ? [event.locationId] : [], // Revisit
      locationId: event.locationId,
      start: startDateTime,
      type: event.details?.rTyNa || event.eventType,
      duration: event.details?.dur,
      externalStatus: event.attendeeStatus,
      practitionerName: event.details?.orNa,
      providerIds: event.organizerId ? [event.organizerId] : [],
      person: {
        firstName: event.attendeeDetails?.firstName,
        lastName: event.attendeeDetails?.lastName,
        preferredName: event.attendeeDetails?.preferredName,
        personId: event.attendeeDetails?.personId,
        gender: event.attendeeDetails?.gender,
        status: event.attendeeDetails?.status,
        birthDate: event.attendeeDetails?.birthdate,
        householdId: event.attendeeDetails?.householdId,
        mobilePhone: event.attendeeDetails?.contactInfo?.find(
          (contact) => contact.type === ContactType_Enum.PHONE_MOBILE
        )?.destination,
        homePhone: event.attendeeDetails?.contactInfo?.find((contact) => contact.type === ContactType_Enum.PHONE_HOME)
          ?.destination,
        workPhone: event.attendeeDetails?.contactInfo?.find((contact) => contact.type === ContactType_Enum.PHONE_WORK)
          ?.destination,
      },
      createdBySourceId: event.sourceTenantId,
      workstationIds: event.operatoryId ? [event.operatoryId] : [],
      isNonIntegratedSource: !event.isIntegrated,
      statusOfficeView: event.attendeeStatus,
      referenceId: event.referenceId ?? '',
    };
  });
};

export const convertV3PractitionerToProvider = (practitioners: Practitioner[]): Provider[] => {
  return practitioners.map((practitioner: Practitioner) => {
    return {
      id: practitioner.id,
      firstName: practitioner.firstName,
      lastName: practitioner.lastName,
      publicDisplayName: practitioner.displayName || '',
      locationId: practitioner.locationId,
      resourceName: practitioner.externalName,
      externalId: practitioner.externalId,
      publicDisplayImage: practitioner?.profile?.picUrl,
      createdAt: practitioner.createdAt,
      updatedAt: practitioner.updatedAt,
      publicDisplayDescription: practitioner.profile?.bio || '',
      sourceId: practitioner.sourceTenantId,
    };
  });
};
