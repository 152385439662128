import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Placement } from '@floating-ui/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import { DEFAULT_FIELD_EMPTY_VALUE } from '../../constants';

type EmptyCellProps = {
  children?: ReactNode;
  edited?: boolean;
  placement?: Placement;
  showTooltip?: boolean;
  tooltipText?: string;
};

export const EmptyCell = ({
  children,
  edited = false,
  placement = 'top',
  showTooltip = true,
  tooltipText,
}: EmptyCellProps) => {
  const { t } = useTranslation('analytics');

  const { tooltipProps, triggerProps, Tooltip } = useTooltip({
    placement,
  });

  return (
    <>
      <div {...triggerProps} css={styles.wrapper}>
        {children ?? <Text>{DEFAULT_FIELD_EMPTY_VALUE}</Text>}
        {edited && (
          <Text size='small' color='subdued' css={styles.editedText}>
            {t('Edited')}
          </Text>
        )}
      </div>
      {showTooltip && <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>}
    </>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    align-items: center;
    cursor: pointer;
    width: 100%;
  `,
  editedText: css`
    font-style: italic;
  `,
};
