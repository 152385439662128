import { ModalControlModalProps, Tray } from '@frontend/design-system';

type TrayContainerProps = {
  children: React.ReactNode;
  modalProps: ModalControlModalProps;
  onModalClose?: () => void;
  maxWidth?: number;
  mountTarget?: string;
};

export const TrayContainer = ({ children, modalProps, mountTarget = '', onModalClose }: TrayContainerProps) => {
  return (
    <Tray
      width='medium'
      mountTarget={mountTarget}
      autoFocusTimeout={3600000 * 2}
      {...modalProps}
      onClose={onModalClose ?? modalProps.onClose}
    >
      {children}
    </Tray>
  );
};
