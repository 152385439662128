import { SchemaScheduleListAppointments } from './api';
import { ProvidersExceptionsForMultipleProvidersOfMultiLocations } from './types';

export const queryKeys = {
  base: ['schedule'] as const,
  providerExceptions: (req: ProvidersExceptionsForMultipleProvidersOfMultiLocations['input']) => [
    ...queryKeys.base,
    'providerExceptions',
    req,
  ],
  appointments: (req: SchemaScheduleListAppointments['input']) => [...queryKeys.base, 'appointments', req],
};
