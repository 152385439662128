import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { ScheduleTypes } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersApi, ServiceProvidersConstants } from '@frontend/api-service-providers';
import { PaginatedList } from '@frontend/components';
import { formatDate, getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, DatePickerField, Heading, SpinningLoader, styles as dsStyle, useForm } from '@frontend/design-system';
import { queryKeys } from '../../../../../../query-keys';
import { LocationAccordionList } from '../../../../../components/location-accordion-list';
import { useScheduleActionsContainerMethod } from '../../../../../context/ScheduleActionsContainerContext';
import {
  useGetCalendarEventsV3Data,
  useGetCombinedProvidersList,
  useGetProvidersListForMultipleLocationIds,
  useAppointmentsInfoShallowStore,
  useGetPractitionersV3Data,
} from '../../../../../hooks';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../stores/use-schedule-actions-appointment-list-store';
import { getCalendarStartAndEndDateTime } from '../../../../../utils/date-helpers';
import {
  convertV3CalendarEventToAppointmentDetails,
  convertV3PractitionerToProvider,
} from '../../../../CalendarV3/helpers';
import { PatientListRow } from '../../../components/PatientListRow';
import { FiltersContainer } from './Filters/FilterContainer';
import { ScheduleMassMessageTrackingIds } from './trackingIds';

export const ScheduleMassMessages = () => {
  const { getScopeName, selectedLocationIds } = useAppScopeStore();

  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const {
    selectedDate: scheduleActionsSelectedDate,
    setSelectedDate: setScheduleActionsSelectedDate,
    setAppointmentList,
    appointmentList,
    setDefaultAppointmentList,
    statusFilter,
    setDefaultProviderList,
    insuranceStatusFilters,
  } = useScheduleActionsAppointmentListInfoShallowStore(
    'selectedDate',
    'setSelectedDate',
    'setAppointmentList',
    'appointmentList',
    'setDefaultAppointmentList',
    'statusFilter',
    'setDefaultProviderList',
    'insuranceStatusFilters'
  );

  const { selectedDate: scheduleActionsDate, setSelectedDate } = useScheduleActionsContainerMethod();

  const { selectedDate: calendarViewDate } = useAppointmentsInfoShallowStore('selectedDate');

  const selectedDate = scheduleActionsSelectedDate || scheduleActionsDate || calendarViewDate;

  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      date: { type: 'date', value: selectedDate || getTodaysDate('MM/DD/YYYY') },
    },
  });

  const hasValidDate = !!dayjs(values.date).isValid();

  useEffect(() => {
    if (values.date) {
      setSelectedDate(formatDate(values.date, 'MM/DD/YYYY'));
    }
  }, [values.date]);

  const [startDate, endDate] = useMemo(() => {
    if (!hasValidDate) return [];
    const startDateInUTC = dayjs(values.date).utc().toISOString();
    const endDateInUTC = dayjs(values.date).utc().add(1, 'day').toISOString();
    return [startDateInUTC, endDateInUTC];
  }, [values.date]);

  const { data: appointmentsData, isLoading: isAppointmentsDataLoading } = useQuery({
    queryKey: queryKeys.appointments({
      equals: {
        locationIds: selectedLocationIds,
        insuranceStatuses: insuranceStatusFilters,
      },
      between: { start: startDate, end: endDate },
    }),
    queryFn: () =>
      ServiceProvidersApi.listAppointments({
        between: { start: startDate, end: endDate },
        equals: {
          locationIds: selectedLocationIds,
          insuranceStatuses: insuranceStatusFilters,
        },
      }),
    retry: 1,
    cacheTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: !isScheduleV3FlagEnabled && !!selectedLocationIds?.length && hasValidDate,
  });

  const { startDateTime: calendarEventsStartDate, endDateTime: calendarEventsEndDate } = getCalendarStartAndEndDateTime(
    dayjs(values.date).toString()
  );

  const { data: calendarEventsData, isLoading: isLoadingCalendarEvents } = useGetCalendarEventsV3Data({
    request: {
      startDateTime: calendarEventsStartDate,
      endDateTime: calendarEventsEndDate,
      page: 1,
      limit: 50,
    },
    selectedLocationIds,
    selectedLocationId: selectedLocationIds[0],
    enabled: isScheduleV3FlagEnabled && !!selectedLocationIds?.length && hasValidDate,
  });

  // Convert calendarEvents to appointments format to display in appointments list and store in ScheduleActionsAppointmentListInfo store
  useEffect(() => {
    const isScheduleV3 = !!calendarEventsData?.events?.length && isScheduleV3FlagEnabled;
    const appointmentsList = isScheduleV3
      ? convertV3CalendarEventToAppointmentDetails(calendarEventsData?.events)
      : appointmentsData?.appointments;

    !statusFilter && setAppointmentList(appointmentsList ?? []);
    setDefaultAppointmentList(appointmentsList ?? []);
  }, [appointmentsData, calendarEventsData?.events, isScheduleV3FlagEnabled]);

  // get and set the provider list from the store
  const { data: providers } = useGetProvidersListForMultipleLocationIds(
    { locationIds: selectedLocationIds },
    !isScheduleV3FlagEnabled
  );

  const { data: practitionerData } = useGetPractitionersV3Data({
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedLocationIds,
    showHiddenOnCalendar: true,
    pageConfig: {
      page: 1,
      limit: 50,
      searchValue: '',
    },
  });

  // get the full provider list, including the providers that have appointments but no official provider created. This is to ensure that all providers are displayed in the provider filter, and all appointments can be filtered by their assigned provider.
  const fullProviderList = useGetCombinedProvidersList(
    appointmentsData ?? {},
    (providers ?? []) as ScheduleTypes.Provider[]
  );

  // set the default provider list in the store for v2
  useEffect(() => {
    if (!isScheduleV3FlagEnabled) {
      setDefaultProviderList(fullProviderList);
    }
  }, [fullProviderList, isScheduleV3FlagEnabled]);

  // set the default provider list in the store for v3 by converting v3 practitioners to providers
  useEffect(() => {
    if (isScheduleV3FlagEnabled) {
      const providersList = convertV3PractitionerToProvider(practitionerData?.providers || []);
      setDefaultProviderList(providersList);
    }
  }, [practitionerData?.providers, isScheduleV3FlagEnabled]);

  // set the schedule pulse selected date for calendar and data fetching
  useEffect(() => {
    if (scheduleActionsSelectedDate) {
      setSelectedDate(formatDate(scheduleActionsSelectedDate, 'MM/DD/YYYY'));
      seedValues({ date: scheduleActionsSelectedDate });
    }
  }, [scheduleActionsSelectedDate]);

  // clear schedule pulse selected date on unmount
  useEffect(() => {
    return () => setScheduleActionsSelectedDate('');
  }, []);

  const locationInfoList = useMemo(() => {
    return selectedLocationIds.map((locationId) => ({
      locationId,
      name: getScopeName(locationId),
      count: appointmentList.filter((appt) => appt.locationId === locationId).length,
    }));
  }, [appointmentList, selectedLocationIds]);

  if (isAppointmentsDataLoading || isLoadingCalendarEvents) {
    return (
      <div css={[dsStyle.flexCenter, { height: '50%' }]}>
        <SpinningLoader size='medium' />
      </div>
    );
  }

  if (!appointmentList) return null;

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <div css={styles.panelTopFilterSection}>
        <DatePickerField
          {...getFieldProps('date')}
          data-trackingid={ScheduleMassMessageTrackingIds.datePickerField}
          css={{ width: 190, backgroundColor: theme.colors.white }}
          label=''
        />
        <FiltersContainer />
      </div>
      <LocationAccordionList ContentComponent={SingleLocationView} locationInfoList={locationInfoList} />
    </div>
  );
};

interface SingleLocationViewProps {
  locationId: string;
  height?: number | string;
}

const SingleLocationView = ({ locationId, height }: SingleLocationViewProps) => {
  const { t } = useTranslation('schedule-pulse');
  const { setHideTabs, setSelectedLocationId } = useScheduleActionsContainerMethod();
  const { appointmentList: allAppointments } = useScheduleActionsAppointmentListInfoShallowStore('appointmentList');

  const appointments = useMemo(
    () => (allAppointments ?? []).filter((appt) => appt.locationId === locationId),
    [allAppointments, locationId]
  );

  return (
    <>
      <div css={styles.locationTopSection}>
        <Heading level={3}>
          {appointments.length ? t('{{count}} Patients', { count: appointments.length }) : t('No Appointments')}
        </Heading>
        <Button
          variant='tertiary'
          trackingId={ScheduleMassMessageTrackingIds.contactPatientsBtn}
          disabled={!appointments.length}
          onClick={() => {
            setSelectedLocationId(locationId);
            setHideTabs(true);
          }}
        >
          {t('Contact Patients')}
        </Button>
      </div>

      <div css={{ background: theme.colors.white, flex: 1 }}>
        <PaginatedList
          emptyStateConfig={{
            header: t('No Appointments found'),
            type: 'schedule',
          }}
          height={typeof height === 'number' ? height - 65 : height ?? '100%'} // 65px is the height of the top section
          listData={appointments}
          renderListItem={({ listItem }) => (
            <PatientListRow
              appointments={appointments}
              appt={listItem}
              shouldShowAppointmentModal
              locationId={locationId}
            />
          )}
          endOfListText=''
        />
      </div>
    </>
  );
};

const styles = {
  panelTopFilterSection: css({
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignItems: 'flex-start',
    flexShrink: 0,
  }),

  locationTopSection: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.neutral10}`,
  }),
};
