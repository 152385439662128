import React from 'react';
import { css } from '@emotion/react';
import { Placement } from '@floating-ui/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';

type EditableFieldProps = {
  displayComponent?: React.ReactNode;
  editComponent?: React.ReactNode;
  editMode?: boolean;
  isEdited?: boolean;
  label: string;
  placement?: Placement;
  showTooltip?: boolean;
  tooltipText?: string;
};

export const EditableField: React.FC<EditableFieldProps> = ({
  displayComponent,
  editComponent,
  editMode = false,
  label,
  isEdited = false,
  placement = 'top',
  showTooltip = false,
  tooltipText,
}) => {
  const { t } = useTranslation('analytics');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({
    placement,
  });

  return (
    <div css={styles.fieldWrapper}>
      <div css={styles.labelWrapper}>
        <Text color='subdued'>{label}</Text>
        {isEdited && (
          <Text
            color='subdued'
            size='small'
            css={css`
              font-style: italic;
            `}
          >
            {t('Edited')}
          </Text>
        )}
      </div>
      <div>
        {editMode ? (
          editComponent
        ) : (
          <div {...triggerProps} css={styles.displayWrapper(showTooltip)}>
            {displayComponent}
          </div>
        )}
      </div>
      {showTooltip && <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>}
    </div>
  );
};

const styles = {
  fieldWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacing(1.5)};

    @media screen and (max-width: ${breakpoints.small.min}px) {
      align-items: flex-start;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    }
  `,
  labelWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
  displayWrapper: (showTooltip: boolean) => css`
    cursor: ${showTooltip ? 'pointer' : 'default'};
    width: ${showTooltip ? theme.spacing(5) : 'auto'};
    text-align: right;
    @media screen and (max-width: ${breakpoints.small.min}px) {
      text-align: left;
    }
  `,
};
