import { useState } from 'react';
import { css } from '@emotion/react';
import {
  PhoneHours,
  PhoneHoursScheduleRule,
  ReadPhoneHoursResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, Tray, useModalControl, TextLink } from '@frontend/design-system';
import { dayOfWeekMap, groupByDayOfWeek } from '../../utils/dates';
import { CallRoutesLink } from './call-routes-link';

// Helper function to format time from 24-hour format to 12-hour format
const formatTime = (time: string, lowercase = false) => {
  if (lowercase) {
    // Return time in following format: 1:00 pm
    return dayjs(time, 'HH:mm:ss').format('h:mm a');
  }

  // Return time in following format: 1:00 PM
  return dayjs(time, 'HH:mm:ss').format('h:mm A');
};

export const DayHoursList = (props: { phoneHours: PhoneHoursScheduleRule[]; useLargerFonts?: boolean }) => {
  const dayHours = groupByDayOfWeek(props.phoneHours);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
      `}
    >
      {Object.entries(dayHours).map(([dayOfWeek, { openStartTime, openEndTime, breaks }]) => (
        <DayHoursListItem
          key={dayOfWeek}
          dayOfWeek={Number(dayOfWeek)}
          openStartTime={openStartTime}
          openEndTime={openEndTime}
          breaks={breaks}
          useLargerFonts={props.useLargerFonts}
        />
      ))}
    </div>
  );
};

interface PhoneHoursTrayProps {
  modalControls: ReturnType<typeof useModalControl>;
  selectedPhoneHour: ReadPhoneHoursResponse;
  mountTarget: string;
  phoneHoursListData: PhoneHours[];
  onEditClick: (phoneHourId: string) => void;
}
export const PhoneHoursTray = ({
  modalControls,
  selectedPhoneHour,
  mountTarget,
  phoneHoursListData,
  onEditClick,
}: PhoneHoursTrayProps) => {
  const { t } = useTranslation('phone');
  const [currentIndex, setCurrentIndex] = useState(() =>
    phoneHoursListData.findIndex((item) => item.id === selectedPhoneHour.id)
  );

  const currentPhoneHour = phoneHoursListData[currentIndex];

  const isPreviousDisabled = currentIndex <= 0;
  const isNextDisabled = currentIndex >= phoneHoursListData.length - 1;

  const handlePreviousClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < phoneHoursListData.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  if (!modalControls) return null;
  return (
    <>
      <Tray
        mountTarget={mountTarget}
        {...modalControls.modalProps}
        width='medium'
        css={{
          gap: theme.spacing(2),
          overflowY: 'auto',
        }}
      >
        <Tray.Header Buttons={<Button iconName='x' onClick={() => modalControls?.closeModal()} variant='secondary' />}>
          {t('Phone Hours')}
        </Tray.Header>
        <div>
          <Text color='light' size='medium'>
            {t('Call Route')}
          </Text>
          <CallRoutesLink scheduleCallRoutes={currentPhoneHour.scheduleCallRoute} />
        </div>
        {currentPhoneHour && (
          <div
            key={currentPhoneHour.id}
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Heading level={3}>{currentPhoneHour?.name}</Heading>
              <TextLink
                onClick={() => onEditClick(currentPhoneHour.id)}
                css={{ display: 'flex', gap: theme.spacing(0.5), width: 'auto', alignItems: 'center' }}
                weight='bold'
              >
                <Icon name='edit' /> {t('Edit')}
              </TextLink>
            </div>
            <DayHoursList phoneHours={selectedPhoneHour.phoneHours} useLargerFonts={true} />
          </div>
        )}
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(2),
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='secondary' size='large' onClick={handlePreviousClick} disabled={isPreviousDisabled}>
            {t('Previous')}
          </Button>
          <Button variant='secondary' size='large' onClick={handleNextClick} disabled={isNextDisabled}>
            {t('Next')}
          </Button>
        </div>
      </Tray>
    </>
  );
};

const DayHoursListItem = ({
  dayOfWeek,
  openStartTime,
  openEndTime,
  breaks,
  useLargerFonts,
}: {
  dayOfWeek: number;
  openStartTime: string;
  openEndTime: string;
  breaks?: { startTime: string; endTime: string; name: string }[];
  useLargerFonts?: boolean;
}) => {
  const { t } = useTranslation('phone');

  return (
    <article
      key={dayOfWeek}
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: ${theme.spacing(1)};
        border-radius: ${theme.borderRadius.medium};
        border: 1px solid ${theme.colors.neutral20};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Text weight='bold' size={useLargerFonts ? 'large' : 'medium'}>
          {dayOfWeekMap[dayOfWeek as keyof typeof dayOfWeekMap]}
        </Text>
        {!!openStartTime ? (
          <Text size={useLargerFonts ? 'large' : 'medium'}>
            {formatTime(openStartTime)} - {formatTime(openEndTime)}
          </Text>
        ) : (
          <Text size={useLargerFonts ? 'large' : 'medium'}>{t('Closed')}</Text>
        )}
      </div>

      {!!breaks?.length && (
        <div
          css={css`
            background-color: ${theme.colors.neutral5};
            border-radius: ${theme.borderRadius.medium};
            padding: ${theme.spacing(1)};
          `}
        >
          <Text
            size={useLargerFonts ? 'medium' : 'small'}
            css={css`
              color: ${theme.colors.neutral70};
              border-bottom: 1px solid ${theme.colors.neutral20};
              padding-bottom: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {t('Breaks')}
          </Text>
          {breaks?.map(({ startTime, endTime, name }) => (
            <div
              key={name}
              css={css`
                display: flex;
                justify-content: space-between;
                padding: ${theme.spacing(0.5, 0)};
              `}
            >
              <Text size={useLargerFonts ? 'large' : 'medium'}>{name}</Text>
              <Text size={useLargerFonts ? 'large' : 'medium'}>
                {formatTime(startTime, true)} - {formatTime(endTime, true)}
              </Text>
            </div>
          ))}
        </div>
      )}
    </article>
  );
};
