import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { SettingType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/settings/v1/settings.pb';
import { Bool } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { ClientSettingsApi } from '@frontend/api-client-settings';
import { FaxQueries } from '@frontend/api-fax';
import { FormsSubmission } from '@frontend/api-forms';
import { MessagingSettingsV1 } from '@frontend/api-messaging-settings';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { ContactsHooks } from '@frontend/contacts';
import { Dashboard } from '@frontend/dashboard';
import { useFeatureAccess } from '@frontend/feature-access';
import { useTranslation } from '@frontend/i18n';
import { Icon, type IconName } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { MultiSettingsHooks } from '@frontend/multi-settings';
import { useGetScheduleRequestAndAlertsCount } from '@frontend/schedule';
import { useScopedAppFlagStore } from '@frontend/scope';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, NotificationBadge } from '@frontend/design-system';

interface AllAppsModule {
  showHeader?: boolean;
}

export const AllAppsModule = ({ showHeader = true }: AllAppsModule) => {
  const { t } = useTranslation('dashboard');
  const hasListHomeIconAccess = ContactsHooks.useHasListHomeIconAccess();

  return (
    <>
      {showHeader && <Dashboard.Module.Header title={t('All Apps')} />}
      <Dashboard.Module.Content>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: ${theme.spacing(2.5)};
            padding: ${theme.spacing(1)};
            margin-top: ${theme.spacing(3)};
          `}
        >
          <PhoneAppFeatureButton />
          <MessagesAppFeatureButton />
          <AppFeatureButton icon='contacts' to='/contacts/all-contacts' label={t('Contacts')} />
          <AppFeatureButton icon='lists' to='/contacts/lists' label={t('Lists')} show={hasListHomeIconAccess} />
          <CalendarAppFeatureButton />
          <FormsAppFeatureButton />
          <AppFeatureButton icon='analytics' to='/analytics/dashboard' label={t('Analytics')} />
          <AppFeatureButton icon='reviews-dash' to='/reviews/all-reviews' label={t('Reviews')} />
          <AppFeatureButton icon='payments' to='/payments/invoices' label={t('Payments')} />
          <FaxAppFeatureButton />
        </div>
      </Dashboard.Module.Content>
    </>
  );
};

const CalendarAppFeatureButton = () => {
  const { t } = useTranslation('dashboard');
  const { scheduleAlertsCount } = useGetScheduleRequestAndAlertsCount();

  return <AppFeatureButton count={scheduleAlertsCount} icon='calendar' to='/schedule/calendar' label={t('Schedule')} />;
};

const PhoneAppFeatureButton = () => {
  const { t } = useTranslation('dashboard');
  const voicemailsUnreadCount = PhoneCallsQueries.useVoicemailsUnreadCount();

  return <AppFeatureButton icon='phone' to='/calls/recent-calls' label={t('Phone')} count={voicemailsUnreadCount} />;
};

const MessagesAppFeatureButton = () => {
  const { t } = useTranslation('dashboard');
  const { locationId } = useLocationDataShallowStore('locationId');

  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();

  const { getFeatureFlagValue } = useScopedAppFlagStore();

  const { aggregatedValue: autoMessageInboxVisibility, isLoading: isAutoMessageInboxSettingsLoading } =
    MultiSettingsHooks.useBooleanClientSetting({
      set: ClientSettingsApi.querySetKeys.sets.autoMessageInboxVisibility,
      key: ClientSettingsApi.querySetKeys.keys.autoMessageInboxVisibility,
      defaultValue: 'false',
      locationIds: selectedLocationIds,
    });
  const avoidLastAutomated = autoMessageInboxVisibility !== true;

  const actionableTabVisibilityFF = getFeatureFlagValue('nwx:inbox-actionable-tab');
  const { data: actionableTabIsEnabled, isLoading: isEnableActionableTabLoading } =
    MessagingSettingsV1.Queries.useListQuery(
      {
        settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
        orgId: selectedOrgId,
        locationIds: selectedLocationIds,
      },
      {
        enabled: !!actionableTabVisibilityFF,
        select: (data) => {
          return data.settings.some(({ setting }) => {
            if ('inboxLocationSetting' in setting) {
              return setting.inboxLocationSetting.enableActionableTab ?? false;
            }
            return false;
          });
        },
      }
    );

  const isActionableFeatureEnabled = actionableTabIsEnabled && actionableTabVisibilityFF;

  const { data: inboxUnreadCount } = SMSDataV3.Queries.useListThreadsCountQuery({
    httpOptions: {
      onFetchError: (error) => {
        if (error.message.includes('too many')) {
          console.warn(error);
        } else {
          throw error;
        }
      },
    },
    request: {
      locationId,
      groupIds: selectedLocationIds,
      ...(!isActionableFeatureEnabled && { avoidLastAutomated, statuses: [SMSDataV3.Types.KnownThreadStatus.NEW] }),
      actionable: isActionableFeatureEnabled ? Bool.BOOL_TRUE : Bool.BOOL_UNSPECIFIED,
    },
    options: {
      select: (data) => data.count,
      enabled: !!selectedLocationIds[0] && !isAutoMessageInboxSettingsLoading && !isEnableActionableTabLoading,
    },
  });

  return <AppFeatureButton count={inboxUnreadCount} icon='messaging' to='/messages/inbox' label={t('Messages')} />;
};

const FaxAppFeatureButton = () => {
  const { t } = useTranslation('dashboard');
  const { selectedLocationIds } = useAppScopeStore();
  const { hasAccess: hasFaxAccess } = useFeatureAccess('fax-2-0', Permission.FAX_INBOX_MANAGE);

  const { data: faxUnreadCount } = FaxQueries.useCountFaxes(selectedLocationIds, {
    enabled: hasFaxAccess && selectedLocationIds.length > 0,
  });

  if (!hasFaxAccess) return null;

  return <AppFeatureButton count={faxUnreadCount?.faxCount} icon='fax' to='/fax/inbox' label={t('Fax')} />;
};

const FormsAppFeatureButton = () => {
  const { t } = useTranslation('dashboard');
  const formsSubmissionCount = FormsSubmission.useSubmissionsUnreadCount();

  return (
    <AppFeatureButton count={formsSubmissionCount.data?.count} icon='forms' to='/forms/inbox' label={t('Forms')} />
  );
};

type AppFeatureButtonProps = {
  icon: IconName;
  label: string;
  to: string;
  show?: boolean;
  size?: 'large' | 'small';
  count?: number;
};

export const AppFeatureButton = ({ icon, label, to, show, size = 'large', count }: AppFeatureButtonProps) => {
  if (show === false) return null;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        align-items: center;
        position: relative;
      `}
    >
      <Link
        to={to}
        css={css`
          width: ${size === 'large' ? '72px' : '60px'};
          height: ${size === 'large' ? '72px' : '60px'};
          background-color: ${theme.colors.primary50};
          color: ${theme.colors.white};
          border-radius: ${theme.borderRadius.full};
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease;
          position: relative;
          :hover,
          :focus {
            box-shadow: ${theme.shadows.floating};
          }
          &:hover,
          &:active {
            transform: scale(1.05);
            box-shadow: ${theme.shadows.heavy};
          }

          &:active {
            transform: scale(0.95);
          }
        `}
      >
        <Icon name={icon} size={32} />
        {!!count && (
          <NotificationBadge truncateCount css={{ position: 'absolute', right: 0, top: 0 }}>
            {count}
          </NotificationBadge>
        )}
      </Link>
      <Text size='small'>{label}</Text>
    </div>
  );
};
