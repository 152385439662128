import { ReactNode } from 'react';
import { OverrideType } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Button,
  ModalControlResponse,
  PlusIconSmall,
  SearchIcon,
  SecondaryButton,
  Text,
  TextLink,
  styles,
} from '@frontend/design-system';
import {
  emptyOverrideSectionStyle,
  overrideSectionChipStyle,
  overrideSectionHeadingStyle,
} from '../../views/settings/overrides-styles';
import { DEFAULT_GREETING_PATH } from '../audio-picker/audio-picker';
import { CachedAudioScrubber } from '../common/cached-audio-scrubber';
import useVoicemailBoxes from '../voicemail-box/new/use-voicemail-boxes';

export const EmptyOverrideSection = ({
  openSettingTray,
  className,
  isSideModal,
  editOverride,
}: {
  openSettingTray: () => void;
  className?: string;
  isSideModal?: boolean;
  editOverride?: ModalControlResponse;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });

  return (
    <>
      {isSideModal ? (
        <section
          css={{
            padding: theme.spacing(3),
            background: theme.colors.neutral5,
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), alignItems: 'center' }}>
            <SearchIcon size={24} css={{ color: theme.colors.neutral70 }} />
            <Text size='large' weight='bold'>
              {t('No Active Override')}
            </Text>

            <Button
              variant='secondary'
              size='large'
              onClick={editOverride?.openModal}
              trackingId='phone-portal-btn-openOverrideModal'
              css={{ color: theme.colors.neutral70 }}
            >
              <PlusIconSmall /> {t('Create Override')}
            </Button>
          </div>
        </section>
      ) : (
        <section css={emptyOverrideSectionStyle} className={className}>
          <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            <SearchIcon size={16} color='subdued' />
            <Text size='medium' color='light'>
              {t('No Active Override')}
            </Text>
          </div>
          <TextLink
            onClick={openSettingTray}
            css={{ display: 'flex', gap: theme.spacing(1), width: 'auto', alignItems: 'center' }}
            trackingId='phone-portal-btn-openOverrideModal'
            weight='bold'
          >
            <PlusIconSmall /> {t('Create Override')}
          </TextLink>
        </section>
      )}
    </>
  );
};

type OverrideSectionProps = {
  type: OverrideType;
  statusChip: ReactNode;
  startTime?: string;
  endTime?: string;
  mailboxId?: string;
  media?: {
    id: string;
    name: string;
    path: string;
  };
  forwardingNumber?: ForwardingNumberTypes.FwdNumberModel;
  openRemoveModal: () => void;
  openSettingModal: () => void;
  className?: string;
  isSideModal?: boolean;
  editOverride?: ModalControlResponse;
  removeOverride?: ModalControlResponse;
};

const GridArea = ({ area, title, children }: { area: string; title: ReactNode; children: ReactNode }) => (
  <div css={{ gridArea: area }}>
    <Text as='dt' size='small' color='light'>
      {title}
    </Text>
    <Text
      size='medium'
      as='dd'
      css={[title !== 'Forwarding Number' && styles.truncate, { lineHeight: theme.spacing(4), maxWidth: '288px' }]}
    >
      {children}
    </Text>
  </div>
);

export const OverrideSection = ({
  type,
  statusChip,
  startTime,
  endTime,
  mailboxId,
  media,
  forwardingNumber,
  openRemoveModal,
  openSettingModal,
  className,
  isSideModal,
  editOverride,
  removeOverride,
}: OverrideSectionProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData[selectedLocationIds[0]];
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasCallRoutesFlag = getFeatureFlagValue('call-routes-settings');

  const { data: voicemailBoxes = [] } = useVoicemailBoxes({
    settingsTenantLocation: locationData,
  });

  const voicemailBox = voicemailBoxes?.filter((vm) => vm.mailbox.id === mailboxId);

  return (
    <>
      <section
        className={className}
        css={
          isSideModal
            ? {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                padding: theme.spacing(3),
                background: theme.colors.white,
                border: `1px solid ${theme.colors.neutral20}`,
                borderRadius: theme.borderRadius.medium,
              }
            : undefined
        }
      >
        {!isSideModal && (
          <Text color={'light'} size='small'>
            {t('Override Type')}
          </Text>
        )}
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <Text weight='bold' css={[overrideSectionHeadingStyle, isSideModal && { alignItems: 'flex-start' }]}>
            <div style={{ flex: 1 }}>
              <div
                css={[overrideSectionChipStyle, isSideModal && { flexDirection: 'column', alignItems: 'flex-start' }]}
              >
                {type === OverrideType.OVERRIDE_TYPE_VMO ? t('Send to Voicemail') : t('Forward Call')}

                {statusChip}
              </div>{' '}
            </div>
            {hasCallRoutesFlag ? (
              isSideModal ? (
                <div style={{ display: 'flex', gap: theme.spacing(1.5) }}>
                  <SecondaryButton
                    destructive
                    css={{ width: 'auto' }}
                    onClick={() => removeOverride?.openModal()}
                    trackingId='phone-portal-btn-removeOverride'
                  >
                    <Icon name='remove-small' />
                    {t('Remove')}
                  </SecondaryButton>
                  <Button
                    variant='tertiary'
                    css={{ color: theme.colors.neutral90, padding: theme.spacing(0.5) }}
                    onClick={() => {
                      editOverride?.openModal();
                    }}
                  >
                    <Icon name='edit-small' />
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                  <SecondaryButton
                    style={{ width: 'auto' }}
                    onClick={() => openSettingModal()}
                    trackingId='phone-portal-btn-openEditOverride'
                  >
                    <Icon name='edit-small' />
                    {t('Edit')}
                  </SecondaryButton>
                  <SecondaryButton
                    destructive
                    css={{ width: 'auto' }}
                    onClick={() => openRemoveModal()}
                    trackingId='phone-portal-btn-removeOverride'
                  >
                    <Icon name='remove-small' />
                    {t('Remove')}
                  </SecondaryButton>
                </div>
              )
            ) : (
              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <SecondaryButton
                  destructive
                  css={{ width: 'auto' }}
                  onClick={() => openRemoveModal()}
                  trackingId='phone-portal-btn-removeOverride'
                >
                  {t('Remove Override')}
                </SecondaryButton>
                <SecondaryButton
                  style={{ width: 'auto' }}
                  onClick={() => openSettingModal()}
                  trackingId='phone-portal-btn-openEditOverride'
                >
                  {t('Edit Override')}
                </SecondaryButton>
              </div>
            )}
          </Text>

          <dl
            css={{
              display: 'flex',
              gap: isSideModal ? theme.spacing(2) : theme.spacing(5),
              ...(isSideModal && { flexDirection: 'column' }),
            }}
          >
            {' '}
            {type === OverrideType.OVERRIDE_TYPE_VMO && mailboxId && (
              <GridArea area='yoyo' title={t('Voicemail Box')}>
                {voicemailBox?.[0]?.mailbox?.name}
              </GridArea>
            )}
            {type === OverrideType.OVERRIDE_TYPE_VMO ? (
              <GridArea area='type' title={t('Voicemail Greeting')}>
                {media?.name}
              </GridArea>
            ) : (
              <GridArea area='type' title={t('Forwarding Number')}>
                {`${forwardingNumber?.name} (${forwardingNumber?.number})`}
              </GridArea>
            )}
            {type === OverrideType.OVERRIDE_TYPE_VMO && media?.name && media.name !== 'No Greeting' && (
              <GridArea area='media' title={t('Audio File')}>
                <CachedAudioScrubber
                  filePath={media?.id ? media.path : DEFAULT_GREETING_PATH}
                  mediaId={media?.id ? media.id : 'default'}
                />
              </GridArea>
            )}
          </dl>
          {(startTime || endTime) && (
            <dl css={{ display: 'flex', gap: theme.spacing(5) }}>
              {/* We also check if officeTimezone is defined b/c we don't want to show the wrong tz */}
              {startTime && (
                <GridArea area='start' title={t('Start Time')}>
                  {startTime}
                </GridArea>
              )}
              {/* We also check if officeTimezone is defined b/c we don't want to show the wrong tz */}
              {endTime && (
                <GridArea area='end' title={t('End Time')}>
                  {endTime}
                </GridArea>
              )}
            </dl>
          )}
        </div>
      </section>
    </>
  );
};
