import { useNavigate } from '@tanstack/react-location';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { NEW_CAMPAIGN_ID, SMS_BETA_CAMPAIGN_TYPE } from '../constants';
import { EditorView } from '../types';
import { useBulkMessagingUrlData } from './use-bulk-messaging-url-data';

export const useBulkMessagingNavigator = (campaignType = CampaignType.EMAIL, useCurrentState = false) => {
  const urlData = useBulkMessagingUrlData();
  const navigate = useNavigate();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const baseUrl = urlData.isDraft ? '/bulk/drafts' : '/bulk/all-campaigns';
  const featureUrl = `${baseUrl}/${campaignType === CampaignType.SMS ? 'text' : 'email'}`;

  const generateEditorNavigate = (view: EditorView) => {
    return (campaignId?: string, seedCampaignId?: string, copyAudience = false) => {
      if (seedCampaignId) {
        if (copyAudience) {
          navigate({ to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}?seedId=${seedCampaignId}&copyAudience=true` });
        } else {
          navigate({ to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}?seedId=${seedCampaignId}` });
        }
        return;
      }
      if (campaignId) {
        navigate({ to: `${featureUrl}/${campaignId}/${view}` });
        return;
      }
      if (useCurrentState) {
        switch (urlData.mode) {
          case 'create':
            navigate({ to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}` });
            return;
          case 'duplicate':
            if (urlData.copyAudience) {
              navigate({
                to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}?seedId=${urlData.seedCampaignId}&copyAudience=true`,
              });
            } else {
              navigate({ to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}?seedId=${urlData.seedCampaignId}` });
            }
            return;
          case 'edit':
            navigate({ to: `${featureUrl}/${urlData.campaignId}/${view}` });
            return;
        }
      }
      navigate({ to: `${featureUrl}/${NEW_CAMPAIGN_ID}/${view}` });
    };
  };

  return {
    // TODO: Once the Bulk Text 2.0 beta is released, remove the isTextBeta flag and clean up this logic
    campaigns: (campaignType: CampaignType | typeof SMS_BETA_CAMPAIGN_TYPE = CampaignType.EMAIL) => {
      // navigate({ to: `${baseUrl}/${campaignType === CampaignType.SMS ? 'text' : 'email'}` });
      navigate({
        to: `${baseUrl}/${
          campaignType === CampaignType.SMS
            ? 'text'
            : campaignType === SMS_BETA_CAMPAIGN_TYPE
            ? SMS_BETA_CAMPAIGN_TYPE
            : 'email'
        }`,
      });
    },
    drafts: () => {
      navigate({ to: `/bulk/drafts` });
    },
    composer: generateEditorNavigate('compose'),
    composerWithTemplateId: (
      templateId: string,
      campaignId: string = NEW_CAMPAIGN_ID,
      isEdit?: boolean,
      isSystemTemplate?: boolean
    ) => {
      navigate({
        to: `${featureUrl}/${campaignId}/compose?${
          templateId && templateId !== 'new' ? `templateId=${templateId}` : ''
        }${isEdit ? '&isEdit=true' : ''}${isSystemTemplate ? '&isSystemTemplate=true' : ''}`,
      });
    },
    editor: generateEditorNavigate('editor'),
    settings: () => {
      settingsNavigate({ to: '/email' });
    },
    templates: generateEditorNavigate('templates'),
    new: generateEditorNavigate('new-email'),
    subscriptions: () => {
      settingsNavigate({ to: '/subscriptions/feature/:id', params: { id: 'bulk-messaging' } });
    },
  };
};
